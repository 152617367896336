import { Box, Card, Stack, Typography, Divider, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
// @ts-expect-error types for the package are not up to date
import { DatePickerElement } from "react-hook-form-mui/date-pickers";
import { OrderOut } from "@providers/hop-ord-server/api";
import Handover from "../components/Handover";

interface Props {
  order: OrderOut;
}

const ManualPlanStatusPage = ({ order }: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Stack gap={4}>
      <Card>
        <Box flex={1}>
          <Stack
            direction="row"
            padding={2}
            justifyContent="space-between"
            alignContent="center"
          >
            <Stack>
              <Typography variant="subtitle1">Manual planning</Typography>
              <Typography variant="body2" color="text.secondary">
                Plan delivery method
              </Typography>
            </Stack>
            <Button
              sx={{ alignSelf: "center", height: "max-content" }}
              size="medium"
              variant="outlined"
              onClick={() => navigate(`/fulfillment/${order.id}/automation`)}
            >
              Change
            </Button>
          </Stack>
        </Box>
      </Card>
      <Divider sx={{ width: 1 }} />

      <Handover order={order} />
    </Stack>
  );
};

export default ManualPlanStatusPage;
