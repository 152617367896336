import { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  CircularProgress,
} from "@mui/material";
import PlanningTemplate from "./PlanningTemplate";
import {
  useAutomationIntegrationApiV1ListTemplatesQuery,
  useAutomationIntegrationApiV1ListTemplateDetailsQuery,
  useAutomationIntegrationApiV1GetStructuresQuery,
  useAutomationIntegrationApiV1RequestStructuresMutation,
  useAutomationIntegrationApiV1RequestPlanGenerationMutation,
} from "@providers/hop-ord-server/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { CheckCircle } from "@mui/icons-material";

interface Props {
  orderId: number;
  setOrderStatus: (status: string) => void;
  // TODO: Remove this jank after astro demo
  setCustomStatus: (status: string) => void;
}

const PlanningTemplateApi = ({
  orderId,
  setOrderStatus,
  setCustomStatus,
}: Props): JSX.Element => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [structuresRetrieved, setStructuresRetrieved] =
    useState<boolean>(false);
  const [structuresRequested, setStructuresRequested] =
    useState<boolean>(false);

  const { data: templateNames, isLoading: templateNamesLoading } =
    useAutomationIntegrationApiV1ListTemplatesQuery({
      orderId,
    });

  const { data: template } =
    useAutomationIntegrationApiV1ListTemplateDetailsQuery(
      selectedTemplate
        ? { orderId, templateName: selectedTemplate }
        : skipToken,
    );

  const [sendPlan] =
    useAutomationIntegrationApiV1RequestPlanGenerationMutation();
  const [sendAvailableStructuresRequest] =
    useAutomationIntegrationApiV1RequestStructuresMutation();

  const templateOptions = [
    "",
    ...(templateNames?.map(
      (templateName: { name: string }) => templateName.name,
    ) || []),
  ];

  const { data: availableStructures, isLoading: availableStructuresLoading } =
    useAutomationIntegrationApiV1GetStructuresQuery(
      {
        orderId,
      },
      {
        pollingInterval: 500,
        skip: structuresRetrieved,
      },
    );

  useEffect(() => {
    if (
      !structuresRetrieved &&
      !structuresRequested &&
      !availableStructuresLoading
    ) {
      sendAvailableStructuresRequest({ orderId });
      setStructuresRequested(true);
    }

    if (availableStructures?.structures?.length) {
      setStructuresRetrieved(true);
      return;
    }
  }, [
    structuresRetrieved,
    availableStructuresLoading,
    structuresRequested,
    availableStructures,
    orderId,
  ]);

  if (templateNamesLoading) return <></>;

  return (
    <Stack width={1} height={1} marginBottom={3} gap={2}>
      <Stack direction="row" gap={1} paddingTop={2}>
        <Chip
          color="success"
          icon={<CheckCircle />}
          variant="outlined"
          label={"CT Exists"}
        />
        <Chip
          color="success"
          icon={<CheckCircle />}
          variant="outlined"
          label={"RTStruct Exists"}
        />
      </Stack>
      <Typography variant="h6">Plan delivery method</Typography>
      <Stack direction="row" justifyContent="space-between">
        <ToggleButtonGroup
          sx={{ width: "350px" }}
          value="automated"
          color="primary"
        >
          <ToggleButton fullWidth value="automated">
            Automated Plan
          </ToggleButton>
          <ToggleButton fullWidth value="manual">
            Manual Plan
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Autocomplete
        options={templateOptions || []}
        value={selectedTemplate}
        fullWidth
        onChange={(_: any, newValue: string | null) => {
          setSelectedTemplate(newValue || "");
        }}
        renderInput={(params) => (
          <TextField {...params} label="Automated Template" />
        )}
      />
      {selectedTemplate &&
        (availableStructures ? (
          <PlanningTemplate
            orderId={orderId}
            template={template}
            availableStructures={availableStructures}
            sendPlan={sendPlan}
            selectedTemplate={selectedTemplate}
            setOrderStatus={setOrderStatus}
            setCustomStatus={setCustomStatus}
          />
        ) : (
          <Typography>
            <CircularProgress /> Fetching available structures
          </Typography>
        ))}
    </Stack>
  );
};

export default PlanningTemplateApi;
