import { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  TemplateOptionSerialised,
  StructureSetResponse,
  StructureMapIn,
} from "@providers/hop-ord-server/api";

const CARD_HEADER_SUBTITLE =
  "Structures marked with * are mandatory. Unmapped template structure objectives will be removed for planning automation upon submission. ";

interface Props {
  orderId: number;
  selectedTemplate: string;
  template?: TemplateOptionSerialised;
  availableStructures?: StructureSetResponse | null;
  sendPlan: ({
    orderId,
    machine,
    structureMapIn,
  }: {
    orderId: number;
    machine: string;
    structureMapIn: StructureMapIn;
  }) => void;
  setOrderStatus: (status: string) => void;
  // TODO: Remove this jank after astro demo
  setCustomStatus: (status: string) => void;
}

interface StructureObj {
  name: string;
  type: string;
}

interface StructureMap {
  id: number;
  name: string;
  mandatory: boolean;
  value: string | undefined;
  isValid: boolean;
}

const PlanningTemplate = ({
  orderId,
  availableStructures,
  template,
  sendPlan,
  selectedTemplate,
  setOrderStatus,
  setCustomStatus,
}: Props): JSX.Element => {
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [sentStatus, setSentStatus] = useState<string>("");
  const [structureState, setStructureState] = useState<StructureMap[]>([]);

  const structureOptions = availableStructures?.structures?.map(
    (structureName: { name: string }) => structureName.name,
  );

  const runValidation = (structures: StructureMap[]) => {
    const allValid = structures.every((s) => {
      return !(s.mandatory && !s.value);
    });

    const updatedStructures = structures.map((structure) => {
      const isInvalid = structure.mandatory && !structure.value;
      return {
        ...structure,
        isValid: !isInvalid,
      };
    });

    setStructureState(updatedStructures);
    setIsFormValid(allValid);
    return allValid;
  };

  // On template select, map matching stuctures
  useEffect(() => {
    // reset state on template change
    setIsFormValid(true);
    setHasSubmitted(false);
    setSentStatus("");

    const templateMap: StructureMap[] = [];
    template?.data?.forEach((t, idx) => {
      const initialMappedStruct = availableStructures?.structures.find(
        (option: StructureObj) => option?.name == t.name,
      )?.name;

      templateMap.push({
        id: idx,
        name: t.name,
        mandatory: t.mandatory,
        value: initialMappedStruct,
        isValid: true,
      });
    });

    setStructureState(templateMap);
  }, [template]);

  const onMappingChange = (id: number, newValue: string | null) => {
    const newStructures = structureState.map((s) =>
      s.id === id
        ? { ...s, value: newValue || "", isValid: !(!newValue && s.mandatory) }
        : s,
    );
    setStructureState(newStructures);
    runValidation(newStructures);
  };

  const submitPlanGeneration = async () => {
    // Run validation here if submit hasnt been clicked yet.
    if (!hasSubmitted && !runValidation(structureState)) {
      // validation fails. Dont submit
      setHasSubmitted(true);
      return;
    }
    // Submit has been clicked and / or validation has run successfully. Submit form.
    const structureMap: StructureObj[] = [];
    structureState.forEach((s) => {
      if (s.value) {
        structureMap.push({
          name: s.name,
          type: s.value || "",
        });
      }
    });

    try {
      await sendPlan({
        orderId,
        machine: "",
        structureMapIn: {
          template: selectedTemplate,
          structures: structureMap,
        },
      });
      setSentStatus(`Sent Template: ${selectedTemplate} Successfully`);
    } catch (error) {
      setSentStatus("Error sending request");
      console.error(error);
    }
    setHasSubmitted(true);
    setOrderStatus("submitted");
    setCustomStatus("Plan in progress");
  };

  return (
    <Stack gap={2}>
      <Stack maxHeight="100%" height={1} width={1} marginBottom={1}>
        <Card>
          <CardHeader
            title="Map Structure"
            subheader={CARD_HEADER_SUBTITLE}
            titleTypographyProps={{ variant: "subtitle1" }}
            subheaderTypographyProps={{ variant: "body2" }}
          />
          <CardContent>
            <Stack gap={2}>
              <Stack direction="row">
                <Typography sx={{ width: "100%" }}>
                  Template Structure
                </Typography>
                <Typography sx={{ width: "100%" }}>
                  Available Structure
                </Typography>
              </Stack>
              <Divider />
              <Stack gap={2}>
                {structureState?.map((data: StructureMap) => {
                  return (
                    <Stack direction="row" key={data.id}>
                      <Typography
                        data-testid={`planning-template-key-${data.id}`}
                        lineHeight={3}
                        sx={{ width: "100%" }}
                        variant="body2"
                      >
                        {data?.name} {data?.mandatory && "*"}
                      </Typography>
                      <Autocomplete
                        data-testid={`planning-template-value-${data.id}`}
                        options={structureOptions || []}
                        fullWidth
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            error={!data.isValid}
                            helperText={
                              !data.isValid && "This field is required"
                            }
                            {...params}
                            label=""
                          />
                        )}
                        value={data.value || null}
                        defaultValue=""
                        onChange={(_: any, mappedValue: string | null) => {
                          onMappingChange(data.id, mappedValue);
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Button
          data-testid={"planning-template-submit"}
          size="medium"
          variant="contained"
          color="primary"
          disabled={!isFormValid}
          onClick={() => submitPlanGeneration()}
        >
          Send Plan
        </Button>
        <Typography lineHeight={2}>{sentStatus}</Typography>
      </Stack>
    </Stack>
  );
};

export default PlanningTemplate;
