import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Typography, Avatar, Stack, Badge } from "@mui/material";
import { Role } from "@enums";
import { useAppSelector } from "@hooks";
import { NotificationOut } from "@providers/hop-ord-server/api";
import { dateOfBirthFormat, getAvatarText, timeFromNow } from "@utils";
import { OrderStatus } from "@enums";

interface NotificationItemProps {
  notification: NotificationOut;
  toggleDrawer: () => void;
}

const NotificationItem = ({
  notification,
  toggleDrawer,
}: NotificationItemProps) => {
  const navigate = useNavigate();
  const roles: Role[] = useAppSelector((state) => state?.auth?.roles);
  const handleOrderSelect = useCallback(
    (latestOrderId: number | null) => {
      toggleDrawer();

      // Since the Order could've been edited since the Notification was created we want
      // to ensure that the Notification redirects to the most recent version of the order

      if (!latestOrderId) return;

      let path = roles.includes(Role.DOSIMETRIST)
        ? `/fulfillment/${latestOrderId}`
        : `/order/${latestOrderId}`;

      if (
        latestOrderId &&
        notification.latestOrderStatus !== OrderStatus.DRAFT
      ) {
        path = `${path}/tracker`;
      }

      navigate(path);
    },
    [navigate, toggleDrawer, roles],
  );

  if (!notification.orderId || !notification.orderGroupId) return <></>;

  const patId = notification.patientMrn || "-";
  const dob = dateOfBirthFormat(notification.patientDob || "");

  return (
    <Stack
      direction="row"
      alignItems="top"
      padding="24px 8px"
      sx={{
        ":hover": {
          backgroundColor: theme.palette.action.hover,
          cursor: "pointer",
        },
      }}
      justifyContent="space-between"
      onClick={() =>
        handleOrderSelect(notification?.latestOrderId || notification?.orderId)
      }
    >
      <Stack direction="row" gap={1}>
        <Stack direction="row" alignSelf="baseline" alignItems="center" gap={1}>
          <Badge
            color="error"
            variant="dot"
            invisible={notification.viewed || false}
          ></Badge>
          <Avatar>{getAvatarText(notification.createdBy || "")}</Avatar>
        </Stack>
        <Stack direction="column" gap={0.1}>
          <Typography variant="h6">{notification.content}</Typography>
          <Typography variant="body1">{notification.patientName}</Typography>
          <Typography
            variant="body2"
            sx={{ color: "rgb(0,0,0,.6)", fontSize: "14px" }}
          >
            {`Pat ID: ${patId} DOB: ${dob}`}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="caption" color="grey.700">
        {timeFromNow(notification.createdAt, "date")}
      </Typography>
    </Stack>
  );
};

export default NotificationItem;
