import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface TimerProps {
  startTime: dayjs.Dayjs | null;
  completedTime: dayjs.Dayjs | null;
}

export const Timer = ({ startTime, completedTime }: TimerProps) => {
  const [seconds, setSeconds] = useState<number>(0);

  const getTime = () => {
    // Get difference between current time and start time
    const current = completedTime ? completedTime : dayjs();
    const difference = current.diff(startTime, "second");
    setSeconds(difference);
  };

  const formatTime = () => {
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 200);
    return () => clearInterval(interval);
  }, [startTime, completedTime]);

  return (
    <Typography variant="body2" color="text.secondary">
      {startTime ? formatTime() : "-"}
    </Typography>
  );
};
