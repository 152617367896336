import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridComparatorFn,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import {
  OrderOut,
  OrderStatus as OrderStatusType,
  useWebApiV1ListOrdersQuery,
} from "@providers/hop-ord-server/api";
import {
  Typography,
  Chip,
  Grid,
  Stack,
  Skeleton,
  Divider,
  CustomPalette,
  PaletteColor,
  CustomTheme,
  useTheme,
} from "@mui/material";
import FilterList from "@mui/icons-material/FilterList";
import WarningIcon from "@mui/icons-material/Warning";
import { toLocaleDateTimeString } from "@utils";
import { CustomPagination } from "../Pagination";
import {
  DASH_EMPTY_FIELD,
  orderStatusColourMapping,
  OrderStatus,
  orderStatusMapping,
} from "@enums";
import { FilterSelect } from "@components";
import { useMemo, useState, useCallback, useEffect } from "react";
import { theme } from "@theme";

const DEFAULT_CHECKED = Object.values(orderStatusMapping).filter(
  (value: string) => value != orderStatusMapping[OrderStatus.COMPLETED],
);

const RenderStatusChip = (params: GridRenderCellParams): JSX.Element => {
  const status = params.value as OrderStatusType;
  const theme = useTheme<CustomTheme>();
  const color = theme.palette[
    orderStatusColourMapping[status] as keyof CustomPalette
  ] as PaletteColor;
  const isLatest = params.row?.isLatest;
  return (
    <Stack gap={0.5} sx={{ minWidth: 0 }}>
      <Chip
        label={orderStatusMapping[status]}
        data-testid="status-chip-label"
        sx={{
          backgroundColor: color ? color.main : "default",
          color: color ? color.contrastText : "#000000",
          width: "fit-content",
        }}
        variant="filled"
      />
      {!isLatest && (
        <Stack direction="row" gap={0.5}>
          <WarningIcon color="warning" fontSize="small" />
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.warning.dark,
            }}
          >
            RO unsubmitted changes
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

const RenderPatientDetails = (params: GridRenderCellParams): JSX.Element => {
  const [patientName, patientDob, patientMrn] = params.value;
  let name_and_dob = "-";
  if (patientName) {
    name_and_dob = patientName + " | DOB: -";
    if (patientDob) {
      name_and_dob = name_and_dob
        .slice(0, -1)
        .concat(toLocaleDateTimeString(patientDob, "date"));
    }
  }
  return (
    <Grid container direction="column">
      <Typography variant="inherit">{name_and_dob}</Typography>
      <Typography variant="inherit" color="grey.700">
        Pat ID: {patientMrn || DASH_EMPTY_FIELD}
      </Typography>
    </Grid>
  );
};

const PatientDetailsSortComparator: GridComparatorFn = (
  v1,
  v2,
  param1,
  param2,
) => {
  return gridStringOrNumberComparator(
    v1[0], // patientName is first item in list
    v2[0],
    param1,
    param2,
  );
};

const columns: GridColDef[] = [
  {
    field: "patientDetails",
    headerName: "Patient",
    flex: 1.4,
    renderCell: RenderPatientDetails,
    sortComparator: PatientDetailsSortComparator,
  },
  { field: "orderGroupId", headerName: "Order ID", flex: 1 },
  { field: "orderName", headerName: "Order", flex: 1.2 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: RenderStatusChip,
  },
  { field: "submittedDatetime", headerName: "Submitted Date", flex: 1 },
];

interface IDataTable {
  items: OrderOut[];
  handleRowClick: (orderId: number, status: OrderStatusType) => void;
  isLoading: boolean;
  statusDefaultFilters: string[];
}

interface FilteredOrder {
  patientDetails: (string | null)[];
  orderGroupId: number | null;
  orderName: string;
  submittedDatetime: string;
  status: string;
}

interface OrderListItemSkeletonProps {
  backgroundColor: string;
}

const NoRowsOverlay = (): JSX.Element => {
  return (
    <Typography variant="inherit" pt={1} pl={1} color="grey.700">
      Orders will be displayed here once created
    </Typography>
  );
};

const OrderListItemSkeleton = ({
  backgroundColor,
}: OrderListItemSkeletonProps) => (
  <>
    <Stack
      direction="row"
      height={68}
      justifyContent="flex-start"
      paddingX={2}
      gap={4}
      alignItems="center"
    >
      <Skeleton sx={{ flex: 1.4, bgcolor: backgroundColor }} variant="text" />
      <Skeleton sx={{ flex: 1, bgcolor: backgroundColor }} variant="text" />
      <Skeleton sx={{ flex: 1.2, bgcolor: backgroundColor }} variant="text" />
      <Skeleton sx={{ flex: 1, bgcolor: backgroundColor }} variant="text" />
      <Skeleton sx={{ flex: 1, bgcolor: backgroundColor }} variant="text" />
    </Stack>
    <Divider />
  </>
);

const LoadingOverlay = (): JSX.Element => {
  const mapKeyToColors = {
    0: theme.palette.skeleton.main,
    1: theme.palette.skeleton.levelOne,
    2: theme.palette.skeleton.levelTwo,
    3: theme.palette.skeleton.levelThree,
    4: theme.palette.skeleton.levelFour,
  };
  return (
    <Stack>
      {[...Array(5).keys()].map((n) => {
        const backgroundColor =
          mapKeyToColors[n as keyof typeof mapKeyToColors];
        return (
          <OrderListItemSkeleton key={n} backgroundColor={backgroundColor} />
        );
      })}
    </Stack>
  );
};

export const DataTable = ({
  items,
  handleRowClick,
  isLoading,
  statusDefaultFilters,
}: IDataTable): JSX.Element => {
  const [filteredOrders, setFilteredOrders] = useState<FilteredOrder[]>([]);

  const filters = useMemo(() => {
    return statusDefaultFilters.map(
      (status) => orderStatusMapping[status as OrderStatusType],
    );
  }, [statusDefaultFilters]);

  const [selectedFilters, setSelectedFilters] =
    useState<string[]>(DEFAULT_CHECKED);

  const orders = useMemo(() => {
    if (!items) return [];
    return items.map((item: OrderOut) => {
      return {
        id: item.id,
        patientDetails: [item.patientName, item.patientDob, item.patientMrn],
        orderGroupId: item.orderGroupId,
        orderName: item.orderName || DASH_EMPTY_FIELD,
        submittedDatetime: item.submittedDatetime
          ? toLocaleDateTimeString(item.submittedDatetime)
          : DASH_EMPTY_FIELD,
        status: item.status,
        isLatest: item.isLatest,
      };
    });
  }, [items]);

  const handleEvent: GridEventListener<"rowClick"> = (
    params, // GridRowParams
  ) => {
    const orderId = params?.id;
    const order = orders.find((order) => order.id === orderId);
    if (order === undefined || order.status === undefined) {
      return;
    }
    handleRowClick(orderId as number, order.status as OrderStatusType);
  };

  const updateFilteredOrders = useCallback(
    (filters: string[]) => {
      setFilteredOrders(
        orders.filter((ord) =>
          filters.includes(orderStatusMapping[ord.status]),
        ),
      );
    },
    [orders],
  );

  useEffect(() => {
    updateFilteredOrders(selectedFilters);
  }, [orders, updateFilteredOrders, selectedFilters, filters]);

  return (
    <Stack height="70vh" alignItems="start">
      <FilterSelect
        label="Status"
        options={filters.map((filter: string) => ({
          label: filter,
          value: filter,
        }))}
        startIcon={<FilterList />}
        setSelectedOptions={setSelectedFilters}
        selectedOptions={selectedFilters}
      />
      <DataGrid
        rows={filteredOrders}
        columns={columns}
        onRowClick={handleEvent}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 8 },
          },
        }}
        pageSizeOptions={[8]}
        hideFooter={filteredOrders.length === 0}
        pagination
        loading={isLoading}
        slots={{
          pagination: CustomPagination,
          noRowsOverlay: NoRowsOverlay,
          loadingOverlay: LoadingOverlay,
        }}
        disableVirtualization={true}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
        sx={{
          width: 1,
          border: "none",
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          ".MuiDataGrid-footerContainer": {
            justifyContent: "center",
          },
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
          ".MuiDataGrid-cellContent": {
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "14px",
          },
        }}
      />
    </Stack>
  );
};

interface IOrderTable {
  handleOrderSelect: (orderId: number, status: OrderStatusType) => void;
  statusDefaultFilters: string[];
  recent: boolean;
}
const OrderTable = ({
  handleOrderSelect,
  statusDefaultFilters,
  recent,
}: IOrderTable): JSX.Element => {
  const { data, isLoading, isError } = useWebApiV1ListOrdersQuery({
    limit: 20,
    recent,
  });
  if (isError) return <div>Error</div>;
  return (
    <DataTable
      items={data?.items || []}
      isLoading={isLoading}
      handleRowClick={handleOrderSelect}
      statusDefaultFilters={statusDefaultFilters}
    />
  );
};

export default OrderTable;
