import { useEffect } from "react";
import PlanningTemplateApi from "./pages/PlanningTemplateApi";
import AutomatedPlanStatusPage from "./pages/AutomatedPlanStatusPage";
import ManualPlanStatusPage from "./pages/ManualPlanStatusPage";
import { Stack } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { OrderOut } from "@providers/hop-ord-server/api";

interface Props {
  order: OrderOut;
}

const PlanningAutomation = ({ order }: Props): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  return (
    <Stack width={1} height={1} marginTop={2} marginBottom={2} gap={2}>
      <Routes>
        <Route
          path="/"
          index
          element={
            <PlanningTemplateApi
              orderId={order.id}
              defaultSelectedButton={order.status}
            />
          }
        />
        <Route
          path="/automated"
          element={<AutomatedPlanStatusPage order={order} />}
        />
        <Route
          path="/manual"
          element={<ManualPlanStatusPage order={order} />}
        />
      </Routes>
    </Stack>
  );
};

export default PlanningAutomation;
