import { OrderStatus, StructureStatus } from "@enums";
import { augmentOrderWithAutomationState } from "@pages/OrderTracker/components/PlanningAutomation/utils";
import {
  useAutomationIntegrationApiV1CreateMockStatusMutation,
  useAutomationIntegrationApiV1MockPendingCtRtStructResponseMutation,
  useLazyWebApiV1ListOrdersQuery,
} from "@providers/hop-ord-server/api";
import { useEffect } from "react";

interface Props {
  active: boolean;
  intervalSeconds: number;
  manualDemoTrigger: boolean;
  setManualDemoTrigger: (value: boolean) => void;
}

const DemoProgressionControl = ({
  active,
  intervalSeconds,
  manualDemoTrigger,
  setManualDemoTrigger,
}: Props) => {
  const [trigger] = useLazyWebApiV1ListOrdersQuery({});

  const [mockPendingCTMutation] =
    useAutomationIntegrationApiV1MockPendingCtRtStructResponseMutation();
  const [mockStatusMutation] =
    useAutomationIntegrationApiV1CreateMockStatusMutation();

  const mockNextDemoStep = async () => {
    // Loops through each existing order, determines if it is waiting for a backend responce and calls the relevant mock endpoint to progress one step
    console.log("Next step run");

    // TODO - Refetching the data within the function because it was getting stuck on the old values
    const refetch_result = await trigger({});
    const new_data = refetch_result.data;

    const orderData = new_data?.items
      ?.filter((order) => order.status !== "draft")
      .map(augmentOrderWithAutomationState);

    if (!orderData) return;

    orderData.forEach(async (order) => {
      console.log("in order loop");
      // Plan Automation Status
      const automationStatus = order.automationState.currentStatus;
      const automationSequence = order.automationState.allSteps;

      // CT Status
      if (order.ctStatus === StructureStatus.PENDING) {
        console.log("pending ct");
        mockPendingCTMutation({ orderId: order.id, responseType: "ct_only" });
        return;
      }

      // RT Struct Status
      if (order.rtstructStatus === StructureStatus.PENDING) {
        console.log("pending rtstruct");
        mockPendingCTMutation({
          orderId: order.id,
          responseType: "ct_rt_struct",
        });
        return;
      }

      // Initiate automation planning, both plan sequence and first step as they are done together
      if (
        order.status === OrderStatus.SENT_TO_AUTOMATION &&
        automationStatus === "not_started"
      ) {
        console.log("init");
        await mockStatusMutation({
          orderId: order.id,
          mockStatusIn: {
            statusType: "planning_step_sequence",
            eventDetails: [
              "Initialising",
              "Plan setup",
              "Clinical goal integration",
              "Plan optimisation",
              "Plan completion",
            ],
          },
        });

        await mockStatusMutation({
          orderId: order.id,
          mockStatusIn: {
            statusType: "planning_step",
            eventDetails: "Initialising",
          },
        });

        return;
      }

      // Process the next step in the automation sequence
      if (automationStatus === "in_progress") {
        console.log("nextStep");
        const nextStepIndex = order.automationState.currentStepIndex + 1;
        if (nextStepIndex < automationSequence.length) {
          const nextStep = automationSequence[nextStepIndex];
          mockStatusMutation({
            orderId: order.id,
            mockStatusIn: {
              statusType: "planning_step",
              eventDetails: nextStep,
            },
          });
        } else {
          mockStatusMutation({
            orderId: order.id,
            mockStatusIn: { statusType: "success", eventDetails: null },
          });
        }
        return;
      }
    });
  };

  useEffect(() => {
    // If active, this will automatically progress the demo every intervalSeconds seconds.
    let interval: NodeJS.Timeout | null = null;
    if (active) {
      interval = setInterval(() => {
        mockNextDemoStep();
      }, intervalSeconds * 1000);
    } else {
      interval && clearInterval(interval);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [active, intervalSeconds]);

  useEffect(() => {
    // When the manual trigger is set to True from the parent, progress the demo by one step
    // Doing it this way to keep the functions in this file and avoid using react's verbose useImperativeHandle method
    if (manualDemoTrigger) {
      mockNextDemoStep();
      setManualDemoTrigger(false);
    }
  }, [manualDemoTrigger]);

  return <></>;
};

export default DemoProgressionControl;
