import TextOverflowTooltip from "@components/TextOverflowTooltip/TextOverflowTooltip";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Breadcrumbs, Divider, Link, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface BreadcumbItem {
  label: string;
  link?: string;
}

interface PageHeaderProps {
  title: ReactNode;
  breadcrumb?: BreadcumbItem[];
  subtitle?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
  showDivider?: boolean;
}

const PageHeader = ({
  breadcrumb,
  title,
  subtitle,
  right,
  children,
  showDivider = false,
}: PageHeaderProps) => {
  const appBarHeight = document.getElementById("app-bar")?.clientHeight;
  return (
    <Stack
      pt={2}
      gap={0.5}
      position="sticky"
      marginLeft={-1}
      paddingLeft={1}
      marginRight={-1}
      paddingRight={1}
      zIndex={100}
      top={appBarHeight}
      bgcolor="white"
    >
      <Stack direction="row" justifyContent="space-between" width={1}>
        <Stack gap={1} alignItems="flex-start" overflow="hidden">
          {breadcrumb && (
            <Breadcrumbs
              separator={<ChevronRightIcon />}
              sx={{
                width: 1,
                ".MuiBreadcrumbs-li": {
                  padding: "1px",
                  maxWidth: 1,
                },
              }}
            >
              {breadcrumb.map((item, index) =>
                item.link ? (
                  <Link key={index} href={item.link} underline="hover">
                    <TextOverflowTooltip>{item.label}</TextOverflowTooltip>
                  </Link>
                ) : (
                  <div key={index}>
                    <TextOverflowTooltip>{item.label}</TextOverflowTooltip>
                  </div>
                ),
              )}
            </Breadcrumbs>
          )}
          <Stack direction="row" gap={1} alignItems="flex-start">
            <Typography variant="h5">{title}</Typography>
          </Stack>
          {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
        </Stack>
        {right && right}
      </Stack>
      <Stack>{children}</Stack>
      {showDivider && <Divider />}
    </Stack>
  );
};

export default PageHeader;
