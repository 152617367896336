import { PageHeader, PatientDetails, TextOverflowTooltip } from "@components";
import {
  Chip,
  Palette,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { orderStatusColourMapping, orderStatusMapping } from "@enums";
import {
  OrderOut,
  OrderStatus as OrderStatusType,
} from "@providers/hop-ord-server/api";
import { ReactNode } from "react";
import { grey } from "@mui/material/colors";
interface Props {
  title: string;
  right?: ReactNode;
  orderData?: OrderOut;
  children?: ReactNode;
  customStatus?: string;
  showDivider?: boolean;
}

interface OrderStatusChipProps {
  status: OrderStatusType;
  customStatus?: string;
}

const OrderStatusChip = ({ status, customStatus }: OrderStatusChipProps) => {
  // TODO: revert jank custom status after astro demo
  const orderStatusLabel = customStatus || orderStatusMapping[status];
  const theme = useTheme();
  const color =
    customStatus === customStatus
      ? theme.palette.warning
      : (theme.palette[
          orderStatusColourMapping[
            status ? status : "draft"
          ] as unknown as keyof Palette
        ] as { main: string; dark: string; contrastText: string });
  return (
    <Chip
      sx={{
        ".MuiChip-avatar": {
          color: "#FFFFFF",
          bgcolor: color ? `${color.dark}` : grey[500],
        },
        color: color ? color.contrastText : "default",
        bgcolor: color ? color.main : "default",
      }}
      label={orderStatusLabel}
      variant="filled"
    />
  );
};

const OrderHeader = ({
  title,
  right,
  orderData,
  children,
  customStatus,
  showDivider = false,
}: Props) => {
  const titleContent = orderData ? (
    <Stack
      data-testid="order-header-title"
      gap={1}
      direction="row"
      style={{ marginTop: "0!important" }}
    >
      <span>{title}</span>
      <OrderStatusChip status={orderData.status} customStatus={customStatus} />
    </Stack>
  ) : (
    <Skeleton variant="text" width={120} height={32} />
  );

  const ordername = !orderData ? (
    <Skeleton variant="text" width={300} height={32} />
  ) : (
    <TextOverflowTooltip variant="h6">
      {orderData.orderName === "New Order" ? "-" : orderData.orderName}
    </TextOverflowTooltip>
  );

  const patientDetails = !orderData ? (
    <Skeleton variant="text" width={162} height={24} />
  ) : (
    <Typography variant="subtitle2">
      <PatientDetails
        name={orderData.patientName}
        patId={orderData.patientMrn}
        dob={orderData.patientDob}
      />
    </Typography>
  );

  return (
    <>
      <PageHeader
        title={titleContent}
        right={
          <Stack direction="row" alignItems="baseline" spacing={2}>
            {right}
          </Stack>
        }
        showDivider={showDivider}
      >
        <Stack gap={1}>
          {ordername}
          {patientDetails}
          {children}
        </Stack>
      </PageHeader>
    </>
  );
};

export default OrderHeader;
