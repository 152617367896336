import RemoveCircle from "@mui/icons-material/RemoveCircle";

import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useCallback, useEffect, useState } from "react";
import { OrganAtRiskOut } from "@providers/hop-ord-server/api";
import OarDialog from "./OarDialog";

export interface OarInfo extends OrganAtRiskOut {
  errors?: string[];
  touched?: string[];
}
interface OarTableProps {
  isReadOnly: boolean;
  updateOar: (oar: OrganAtRiskOut) => boolean;
  removeOar: (id: string | number) => void;
  addOar: () => void;
  oarData: OarInfo[];
  organOptions: string[];
  submitCount?: number;
  reportValidity: (isValid: boolean) => void;
  dataTestId: string;
}

const OarTable = ({
  reportValidity,
  oarData,
  removeOar,
  addOar,
  updateOar,
  organOptions,
  dataTestId,
  isReadOnly,
}: OarTableProps) => {
  const isEditable = !isReadOnly;

  const [oarDialogOpen, setOarDialogOpen] = useState(false);
  const [oarIndex, setOarIndex] = useState(-1);

  const addOarWrapper = () => {
    const anyEmpty = oarData.some((oar) => oar.organ === "");
    if (anyEmpty) {
      return;
    }
    addOar();
  };

  const validateOars = useCallback(
    (oarData: OarInfo[]) => {
      // check that all organ fields are filled
      const valid = oarData.every((oar) => oar["organ"] !== "");
      reportValidity(valid);
    },
    [reportValidity],
  );

  useEffect(() => {
    // TODO: Temporary site validation function until we can ingrain things into the datatable better
    validateOars(oarData);
  }, [validateOars, oarData]);

  const hasErrors = oarData.some((oar) => {
    if (oar.errors === undefined) {
      return false;
    }
    return oar.errors.some((err) => oar.touched?.includes(err));
  });

  return (
    <Card
      data-testid={dataTestId}
      style={{ marginLeft: "2px", marginRight: "2px" }}
    >
      <CardHeader
        title="Organs At Risk"
        titleTypographyProps={{ variant: "subtitle1" }}
      />
      {hasErrors && <Alert severity="error">Missing required fields</Alert>}
      <CardContent
        style={{
          paddingTop: 0,
        }}
      >
        <Stack spacing={1}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="20%">Organ</TableCell>
                <TableCell width="70%">Constraints</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {oarData.map((oar, index) => (
                <TableRow key={index}>
                  <TableCell data-testid={`oar-organ-${index}`}>
                    {oar.organ}
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "pre-wrap",
                    }}
                    data-testid={`oar-constraints-${index}`}
                  >
                    {oar.constraints}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        setOarIndex(index);
                        setOarDialogOpen(true);
                      }}
                      data-testid={`oar-edit-${index}`}
                      disabled={!isEditable}
                    >
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => removeOar(oar.id)}
                      disabled={!isEditable}
                      data-testid={`oar-edit-${index}`}
                    >
                      <RemoveCircle />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <OarDialog
            open={oarDialogOpen}
            onClose={() => setOarDialogOpen(false)}
            onSubmit={(oar) => {
              setOarDialogOpen(false);
              updateOar(oar);
            }}
            organAtRisk={oarData[oarIndex]}
            organOptions={organOptions}
          />
          {isEditable && (
            <Stack direction="row" justifyContent="start" paddingTop={1}>
              <Button
                onClick={addOarWrapper}
                variant="outlined"
                startIcon={<AddIcon />}
                data-testid="add-oar"
              >
                Add organ
              </Button>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OarTable;
