import { ReactNode } from "react";
import { AppBar, Toolbar, Stack, Link, Box } from "@mui/material";
import { User } from "@auth0/auth0-react";
import Notifications from "@components/Notifications/Notifications";
import UserMenu from "./UserMenu";

interface MainLayoutProps {
  user?: User;
  accessToken?: boolean;
  children: ReactNode;
}

const Layout: React.FC<MainLayoutProps> = ({ user, accessToken, children }) => {
  const logoURL = new URL("@assets/header_logo.png", import.meta.url).href;
  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        id="app-bar"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Stack flex={1} direction="row" alignItems={"end"} gap={2}>
            <Link
              href="/"
              width="max-content"
              underline="none"
              color="inherit"
              variant="h6"
              style={{ display: "inline-flex" }}
            >
              <img src={logoURL} alt="logo" style={{ height: "60px" }} />
            </Link>
            <Stack
              pb={1.5}
              borderBottom={1}
              width={60}
              alignItems="center"
              marginBottom="-2px"
            >
              <Link
                href="/"
                underline="none"
                color="inherit"
                variant="subtitle2"
                style={{ display: "inline-flex" }}
                paddingBottom="2px"
              >
                HOME
              </Link>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            {user && accessToken && <Notifications />}
            {user && <UserMenu user={user} />}
          </Stack>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      {/* There are redundant stacks because they make the layout work properly with the MUI tables.
       * If you can figure out a simpler way, please do!
       * When testing, make sure to resize the screen and check that the content doesn't go under the
       * sidebar.
       */}
      <Stack flex={1} height="calc(100vh - 64px)" marginTop={8} width={1}>
        <Stack flex={1} direction="row">
          <Stack flex={1} overflow="clip" width={1} minWidth={0}>
            <Box id="main-banner" />
            <Box paddingX={2} height={1}>
              {children}
            </Box>
          </Stack>
          <Box id="layout-right-bar" />
        </Stack>
      </Stack>
    </>
  );
};

export default Layout;
