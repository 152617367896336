import {
  Avatar,
  CircularProgress,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useAuth0, User } from "@auth0/auth0-react";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useWebApiV1ResetDemoDataMutation } from "@providers/hop-ord-server/api";
import { CheckCircle } from "@mui/icons-material";
import DemoProgressionControl from "./DemoProgressionControl";
import { useAppSelector } from "@hooks";

interface Props {
  user: User;
}

const ResetStatusIcon = ({
  isLoading,
  isSuccess,
}: {
  isLoading: boolean;
  isSuccess: boolean;
}) => {
  if (isLoading)
    return <CircularProgress data-testid="reset-inprogress-icon" size={20} />;
  if (isSuccess)
    return <CheckCircle data-testid="data-success-icon" color="success" />;
  return <RestartAltIcon data-testid="reset-icon" />;
};

const DEMO_TENANTS = [
  "tenant:demo",
  "tenant:demo-au",
  "tenant:demo-planning",
  "tenant:demo-planning-au",
];

const UserMenu = ({ user }: Props) => {
  const { logout: auth0Logout } = useAuth0();

  const tenants = useAppSelector((state) => state?.auth?.tenants);
  const isDemoTenant =
    tenants.filter((value) => DEMO_TENANTS.includes(value)).length > 0;
  const inNonDemoTenants =
    tenants.filter((value) => !DEMO_TENANTS.includes(value)).length > 0;

  console.log(tenants);

  const [
    resetDataMutation,
    { isLoading: resetLoading, isSuccess: resetSuccess },
  ] = useWebApiV1ResetDemoDataMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [progressionActive, setProgressionActive] = useState(false);
  const [intervalSeconds, setIntervalSeconds] = useState(5);
  const [manualDemoTrigger, setManualDemoTrigger] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const close = () => {
    setAnchorEl(null);
  };

  const resetDemoData = async () => {
    setProgressionActive(false);
    await resetDataMutation();
  };

  const toggleDemoProgression = () => {
    setProgressionActive(!progressionActive);
  };

  const logout = () => {
    auth0Logout({ logoutParams: { returnTo: window.location.origin } });
    location.reload();
  };

  return (
    <>
      <DemoProgressionControl
        active={progressionActive}
        intervalSeconds={intervalSeconds}
        manualDemoTrigger={manualDemoTrigger}
        setManualDemoTrigger={setManualDemoTrigger}
      />
      <IconButton onClick={handleClick} data-testid="user-menu-toggle-button">
        <Avatar />
      </IconButton>
      <Menu
        data-testid="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={close}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Typography
          data-testid="user-menu-username"
          variant="body2"
          align="center"
          sx={{ p: 2 }}
        >
          {user.name}
        </Typography>

        {isDemoTenant && (
          <>
            {inNonDemoTenants && (
              <Typography
                paddingX={2}
                variant="body2"
                maxWidth={220}
                color="error"
              >
                Controls may not work as you are in some non-demo tenants
              </Typography>
            )}
            <MenuItem
              onClick={resetDemoData}
              disabled={resetLoading}
              sx={{ gap: 1 }}
            >
              <ResetStatusIcon
                isLoading={resetLoading}
                isSuccess={resetSuccess}
              />
              Reset Demo Data
            </MenuItem>

            <MenuItem
              onClick={() => {
                setManualDemoTrigger(true);
              }}
              disabled={resetLoading}
              sx={{ gap: 1 }}
            >
              <SkipNextIcon />
              Manually Progress Demo
            </MenuItem>
            <Stack
              direction="row"
              paddingX={2}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography>Auto Progress Demo</Typography>
              <Switch
                data-testid="demo-progression-switch"
                checked={progressionActive}
                onChange={toggleDemoProgression}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            <Stack
              direction="row"
              paddingX={2}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography>Every</Typography>
              <Input
                data-testid="manual-progression-button"
                sx={{ width: 50 }}
                type="number"
                value={intervalSeconds}
                onChange={(event) =>
                  setIntervalSeconds(Number(event.currentTarget.value))
                }
              />
              <Typography>seconds</Typography>
            </Stack>
          </>
        )}
        <MenuItem onClick={logout} sx={{ gap: 1 }}>
          <LogoutIcon /> Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
