import { Feature, OrderStatus } from "@enums";
import { Box, Stack, Alert, Divider, Button } from "@mui/material";
import { useAppSelector } from "@hooks";
import { useTheme } from "@mui/system";
import {
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
  useWebApiV1GetFeatureSwitchesForOrderQuery,
  OrderOut,
} from "@providers/hop-ord-server/api";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ActionBar from "../../components/ActionBar/ActionBar";
import OrderHeader from "@components/OrderHeader/OrderHeader";
import { toLocaleDateTimeString, checkFeatureActive } from "@utils";
import OrderTrackerDrawer, {
  PanelType,
  DEFAULT_DRAWER,
} from "../OrderTracker/components/OrderTrackerDrawer/OrderTrackerDrawer";
import OrderTrackingUploadPanel from "../OrderTracker/components/OrderTrackingUploadPanel/OrderTrackingUploadPanel";
import { ActionIcons, StatusButtonGroup } from "../OrderTracker/components";
import PortaledMainBanner from "@pages/Layout/PortaledMainBanner";
import WarningIcon from "@mui/icons-material/Warning";
import { usePDF } from "react-to-pdf";
import PdfOrder from "../OrderTracker/components/PdfOrder/PdfOrder";
import PlanAutomationPanel from "./DemoPlanningAutomation/PlanAutomationPanel";

const PAGE_WIDTH = "900px"; // Need to enforce the width so the PDF generation is consistent

export const pdfName = (orderData: OrderOut): string => {
  // The default filename is <first name>_<last name>_<DOB in yyyymmdd>_<MRN if exists>_<order name with _ instead of spaces and - instead of />.PDF
  // e.g. John_Doe_19500612_A1276924_Lung_VMAT_60.0-30.PDF
  const patientName = orderData?.patientName?.split(" ").join("_");
  const orderName = orderData?.orderName
    ?.split(" ")
    .join("_")
    .split("/")
    .join("-");
  const patientDob = orderData?.patientDob
    ? new Date(orderData?.patientDob)
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "")
    : "";
  const patId = orderData?.patientMrn;
  return `${patientName}_${patientDob}${patId ? `_${patId}` : ""}_${orderName}.pdf`;
};

const DemoDosiOrderTrackerPage = () => {
  const { orderId } = useParams();
  const { data: orderData, isLoading: orderLoading } = useWebApiV1OrderQuery({
    orderId: Number(orderId),
  });
  // TODO: remove this jank after astro demo
  const [customStatus, setCustomStatus] =
    useState<string>("Ready for planning");
  const { data: siteGroupData, isLoading: siteGroupLoading } =
    useWebApiV1ListSiteGroupsQuery({
      orderId: Number(orderId),
    });
  const { data: featureSwitchData, isLoading: featureSwitchLoading } =
    useWebApiV1GetFeatureSwitchesForOrderQuery({ orderId: Number(orderId) });

  const tenants = useAppSelector((state) => state?.auth?.tenants);
  const showAutomationIntegration = featureSwitchData
    ? checkFeatureActive(
        featureSwitchData,
        Feature.AUTOMATION_INTEGRATION,
        tenants,
      )
    : false;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDrawerPanel, setSelectedDrawerPanel] =
    useState<PanelType>(DEFAULT_DRAWER);
  const [updateOrder] = useWebApiV1UpdateOrderMutation();
  const theme = useTheme();

  const editingDatetime = orderData?.editingDatetime
    ? toLocaleDateTimeString(orderData?.editingDatetime || "")
    : "";

  const pdfMode = [OrderStatus.COMPLETED, OrderStatus.APPROVED].includes(
    orderData?.status ?? OrderStatus.DRAFT,
  );

  const filename = orderData ? pdfName(orderData) : "";
  const { toPDF, targetRef } = usePDF({ filename });

  if (featureSwitchLoading || orderLoading || siteGroupLoading) {
    return <></>;
  }

  return (
    <>
      {orderData && !orderData?.isLatest && (
        <PortaledMainBanner>
          <Stack>
            <Alert
              severity="warning"
              sx={{ justifyContent: "center" }}
              icon={<WarningIcon />}
            >
              {`Dr ${orderData?.treatingRo} began editing this order at ${editingDatetime} and has not yet submitted the changes.`}
            </Alert>
            <Divider />
          </Stack>
        </PortaledMainBanner>
      )}
      <Stack
        maxWidth={PAGE_WIDTH}
        width="100%"
        marginLeft="auto"
        marginRight="auto"
      >
        <OrderHeader
          title="Plan Tracking"
          orderData={orderData}
          right={
            <ActionIcons
              drawerOpen={drawerOpen}
              orderData={orderData}
              setSelectedPanel={setSelectedDrawerPanel}
              setDrawerOpen={setDrawerOpen}
              isRo={false}
            />
          }
          customStatus={showAutomationIntegration ? customStatus : ""}
        >
          {!showAutomationIntegration && (
            <ActionBar
              text="Please update status to reflect the current stage"
              isLoading={!orderData}
              actions={
                orderData && (
                  <StatusButtonGroup
                    status={orderData.status}
                    statuses={[
                      OrderStatus.SUBMITTED,
                      OrderStatus.IN_PROGRESS,
                      OrderStatus.READY_FOR_RO_APPROVAL,
                      OrderStatus.APPROVED,
                      OrderStatus.REJECTED,
                      OrderStatus.COMPLETED,
                    ]}
                    setStatus={(status) => {
                      updateOrder({
                        orderId: Number(orderId),
                        orderIn: { status },
                      });
                    }}
                  />
                )
              }
            />
          )}
        </OrderHeader>
        {pdfMode && orderData && siteGroupData ? (
          <Stack overflow="auto">
            <Stack width={200} padding={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => toPDF()}
              >
                Download PDF
              </Button>
            </Stack>
            <Stack
              justifySelf="center"
              marginBottom={4}
              padding={2}
              bgcolor={theme.palette.grey[900]}
              width={PAGE_WIDTH}
              minWidth={PAGE_WIDTH}
            >
              <Stack padding={2} height={1} bgcolor="white">
                <Stack padding={2} alignItems="center" ref={targetRef}>
                  <PdfOrder orderId={Number(orderId)} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ) : showAutomationIntegration ? (
          <PlanAutomationPanel
            orderId={Number(orderId)}
            setCustomStatus={setCustomStatus}
          />
        ) : (
          <Stack overflow="auto" height={1} width={1}>
            <Box paddingTop={3}>
              <OrderTrackingUploadPanel
                orderId={Number(orderId)}
                orderData={orderData}
              />
            </Box>
          </Stack>
        )}
      </Stack>
      <OrderTrackerDrawer
        orderId={Number(orderId)}
        open={drawerOpen}
        setOpen={setDrawerOpen}
        selectedPanel={selectedDrawerPanel}
        setSelectedPanel={setSelectedDrawerPanel}
        orderData={orderData}
        siteGroupData={siteGroupData || []}
      />
    </>
  );
};

export default DemoDosiOrderTrackerPage;
