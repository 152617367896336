import OrderTable from "@components/OrderTable/OrderTable";
import { Box, Button, Card, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  OrderStatus as OrderStatusType,
  useWebApiV1CreateOrderMutation,
} from "@providers/hop-ord-server/api";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "@enums";
import { PageHeader } from "@components";

const RODashboard = () => {
  const [addOrder] = useWebApiV1CreateOrderMutation();
  const navigate = useNavigate();

  const statusDefaultFilters = Object.values(OrderStatus);

  const createOrder = async () => {
    const order = await addOrder().unwrap();
    navigate(`/order/${order}`);
  };

  const handleOrderSelect = useCallback(
    (orderId: number, status: OrderStatusType) => {
      let path = `/order/${orderId}`;
      if (status !== OrderStatus.DRAFT) {
        path = `${path}/tracker`;
      }
      navigate(path);
    },
    [navigate],
  );

  return (
    <Stack gap={1.5} pt={1.5}>
      <PageHeader title="Planning Dashboard" />
      <Card>
        <Box padding={1.5} marginBottom={2}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => createOrder()}
            startIcon={<AddIcon />}
          >
            New Order
          </Button>
        </Box>
        <OrderTable
          handleOrderSelect={handleOrderSelect}
          statusDefaultFilters={statusDefaultFilters}
          recent={true}
        />
      </Card>
    </Stack>
  );
};

export default RODashboard;
