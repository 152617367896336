import {
  Card,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { PageHeader, TextOverflowTooltip } from "@components";
import { ArrowDownward, CheckCircle, ArrowUpward } from "@mui/icons-material";
import { theme } from "@theme";
import { toLocaleDateTimeString } from "@utils";
import { useMemo, useState, useEffect, useCallback } from "react";
import { StructureStatus, Feature } from "@enums";
import {
  useWebApiV1GetUserFeatureSwitchesQuery,
  useWebApiV1GetUserPreferencesQuery,
  useLazyWebApiV1SearchOrdersQuery,
} from "@providers/hop-ord-server/api";
import { CtRtMockComponent } from "./CtRtMockComponent";
import SearchAndFilterBar from "./SearchAndFilterBar";
import {
  PlanningStatusCell,
  structStatusColor,
  structStatusIcon,
  searchTextToolTips,
  noSearchTextToolTips,
  planDeliveryColor,
  backgroundColor,
} from "./CommandCenterComponents";
import dayjs from "dayjs";
import { augmentOrderWithAutomationState } from "@pages/OrderTracker/components/PlanningAutomation/utils";

const CommandCenter = () => {
  const { data: featureSwitchData } = useWebApiV1GetUserFeatureSwitchesQuery();
  const { data: userPreferenceData } = useWebApiV1GetUserPreferencesQuery();

  // All the good stuff for the order search
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    userPreferenceData?.statuses || [],
  );
  const [selectedSite, setSelectedSite] = useState<string[]>(
    userPreferenceData?.treating_departments || [],
  );
  const [selectedPhysician, setSelectedPhysician] = useState<string[]>(
    userPreferenceData?.physicians || [],
  );
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<string[]>(
    userPreferenceData?.quick_filters || [],
  );

  const [trigger, { data, isLoading, isFetching }] =
    useLazyWebApiV1SearchOrdersQuery();

  useEffect(() => {
    trigger({
      searchText: debouncedSearchText,
      siteFilter: selectedSite.join(","),
      physicianFilter: selectedPhysician.join(","),
      statusFilter: selectedStatus.join(","),
      quickFilter: selectedQuickFilters.join(","),
      ordering: "-submitted_datetime",
    });
  }, [
    debouncedSearchText,
    selectedSite,
    selectedPhysician,
    selectedStatus,
    selectedQuickFilters,
  ]);

  const dataLoading = isLoading || isFetching;

  const [sortBy, setSortBy] = useState<string>("rxSubmitted");
  const [sortDirection, setSortDirection] = useState<string>("desc");

  const showMockControls = featureSwitchData?.find(
    (feature) => feature.name === Feature.MOCK_CONTROLS,
  );

  const filteredOrderData = useMemo(
    () =>
      data
        ?.filter((order) => order.status !== "draft")
        .map(augmentOrderWithAutomationState),
    [data],
  );

  useEffect(() => {
    if (userPreferenceData) {
      setSelectedStatus(userPreferenceData?.statuses || []);
      setSelectedSite(userPreferenceData?.treating_departments || []);
      setSelectedPhysician(userPreferenceData?.physicians || []);
      setSelectedQuickFilters(userPreferenceData?.quick_filters || []);
    }
  }, [userPreferenceData]);

  // Addded debounce to the search text to prevent lots of spam
  const debouncedSetSearchText = useCallback(
    debounce(setDebouncedSearchText, 150),
    [],
  );
  useEffect(() => {
    debouncedSetSearchText(searchText);
  }, [searchText]);

  const handleSortClick = (newSortBy: string) => {
    if (sortBy === newSortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(newSortBy);
    }
  };

  const noResultsMessage = searchText ? ` for "${searchText}"` : "";
  const toolTips = searchText ? searchTextToolTips : noSearchTextToolTips;

  const stickyColumn = {
    position: "sticky",
    left: 0,
    zIndex: "10",
    backgroundColor: theme.palette.background.paper,
  };

  return (
    <Stack
      gap={3}
      padding={2}
      height={1}
      maxHeight={1}
      maxWidth="1700px"
      marginLeft="auto"
      marginRight="auto"
    >
      <PageHeader title="Command Center" />
      <SearchAndFilterBar
        setSearchText={setSearchText}
        searchText={searchText}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        selectedPhysician={selectedPhysician}
        setSelectedPhysician={setSelectedPhysician}
        selectedQuickFilters={selectedQuickFilters}
        setSelectedQuickFilters={setSelectedQuickFilters}
      />
      <Card>
        <TableContainer
          sx={{
            flexGrow: 1,
            overflow: "auto",
            height: 1,
            maxHeight: "calc(100vh - 250px)",
          }}
        >
          <Table
            stickyHeader
            size="small"
            sx={{
              minWidth: "1200px",
              "& .MuiTableCell-head": { padding: 2 },
              "& .MuiTableCell-body": {
                padding: dataLoading ? 0 : 1,
                borderRight: "1px solid #E0E0E0",
              },
              "& .MuiTableRow-root:last-child td": {
                borderRight: "1px solid #E0E0E0",
              },
              "& .MuiTableCell-root:last-child": {
                borderRight: 0,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width={"19%"} sx={{ ...stickyColumn, zIndex: "11" }}>
                  Patient
                </TableCell>
                <TableCell width={"10%"}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={0.5}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleSortClick("rxSubmitted");
                    }}
                  >
                    {sortBy === "rxSubmitted" && sortDirection === "asc" ? (
                      <ArrowUpward color="secondary" fontSize="small" />
                    ) : null}
                    {sortBy === "rxSubmitted" && sortDirection === "desc" ? (
                      <ArrowDownward color="secondary" fontSize="small" />
                    ) : null}
                    Submitted
                  </Stack>
                </TableCell>
                <TableCell width={"20%"} sx={{ textAlign: "center" }}>
                  Prescription
                </TableCell>
                <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                  CT
                </TableCell>
                <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                  RTStruct
                </TableCell>
                <TableCell width={"33%"}>Planning Status</TableCell>
                <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                  Handover
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data?.length || dataLoading ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{ border: "none !important" }}>
                    {dataLoading ? (
                      <LinearProgress color="primary" />
                    ) : (
                      <Stack padding={2}>
                        <Typography variant="subtitle2">
                          {`No results found${noResultsMessage}.`}
                        </Typography>
                        <Typography paddingTop={2} variant="overline">
                          TRY
                        </Typography>
                        {toolTips.map((tip, index) => (
                          <Typography
                            component="li"
                            key={index}
                            variant="body2"
                          >
                            {tip}
                          </Typography>
                        ))}
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                filteredOrderData?.map((order, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "102px",
                      minHeight: "102px",
                    }}
                  >
                    {/* Patient */}
                    <TableCell scope="row" sx={{ ...stickyColumn }}>
                      <Typography variant="subtitle2">
                        {order.patientName}
                      </Typography>
                      <Typography variant="body2">
                        Pat ID: {order.patientMrn} | DOB:{" "}
                        {order.patientDob
                          ? toLocaleDateTimeString(order.patientDob, "date")
                          : "-"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Site: {order.treatingDepartment} | Physician:{" "}
                        {order.treatingRo}
                      </Typography>
                    </TableCell>

                    {/* Rx Submitted */}
                    <TableCell
                      sx={{ backgroundColor: theme.palette.success.light }}
                    >
                      <Typography variant="body2">
                        {order.submittedDatetime
                          ? toLocaleDateTimeString(order.submittedDatetime)
                          : "-"}
                      </Typography>
                    </TableCell>

                    {/* Prescription */}
                    <TableCell
                      sx={{ backgroundColor: theme.palette.success.light }}
                    >
                      <Stack gap={0.5}>
                        <TextOverflowTooltip
                          variant="body2"
                          data-testid={`${index}-prescription-name`}
                          forceTooltip={true}
                          noWrap={false}
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                          }}
                        >
                          {order.orderName}
                        </TextOverflowTooltip>
                        <Typography
                          variant="body2"
                          color={planDeliveryColor(order.planDelivery)}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {order?.planDelivery}
                        </Typography>
                      </Stack>
                    </TableCell>

                    {/* CT */}
                    <TableCell
                      sx={{
                        backgroundColor: structStatusColor(order.ctStatus),
                      }}
                    >
                      <Stack alignItems="center" width={1} gap={1}>
                        {structStatusIcon(order.ctStatus)}
                        <Typography
                          color={
                            order?.ctStatus == StructureStatus.COMPLETE
                              ? "text.primary"
                              : "text.secondary"
                          }
                          data-testid={`${index}-ct-status-text`}
                        >
                          {order?.ctStatus == StructureStatus.COMPLETE
                            ? "Available"
                            : order?.ctStatus == StructureStatus.SKIPPED
                              ? "Skipped"
                              : "Pending"}
                        </Typography>

                        {order?.ctStatus == StructureStatus.PENDING &&
                          showMockControls && (
                            <CtRtMockComponent
                              orderId={order?.id}
                              ctOnly={false}
                            />
                          )}
                      </Stack>
                    </TableCell>

                    {/* RTStruct */}
                    <TableCell
                      sx={{
                        backgroundColor: structStatusColor(
                          order?.rtstructStatus,
                        ),
                      }}
                    >
                      <Stack alignItems="center" width={1} gap={1}>
                        {structStatusIcon(order?.rtstructStatus)}

                        <Typography
                          color={
                            order?.rtstructStatus == StructureStatus.COMPLETE
                              ? "text.primary"
                              : "text.secondary"
                          }
                          data-testid={`${index}-rtstruct-status-text`}
                        >
                          {order?.rtstructStatus == StructureStatus.COMPLETE
                            ? "Available"
                            : order?.rtstructStatus == StructureStatus.SKIPPED
                              ? "Skipped"
                              : "Pending"}
                        </Typography>

                        {order?.ctStatus == StructureStatus.PENDING &&
                          showMockControls && (
                            <CtRtMockComponent
                              orderId={order?.id}
                              ctOnly={false}
                            />
                          )}
                      </Stack>
                    </TableCell>

                    {/* Planning Status */}
                    <TableCell
                      sx={{ backgroundColor: backgroundColor(order) }}
                      data-testid={`${index}-planning-status`}
                    >
                      <PlanningStatusCell order={order} />
                    </TableCell>

                    {/* Handover */}
                    <TableCell
                      sx={{
                        backgroundColor: order.handoverDatetime
                          ? theme.palette.success.light
                          : theme.palette.grey[100],
                      }}
                      data-testid={`${index}-handover-status`}
                    >
                      <Stack alignItems="center" width={1} gap={1}>
                        {order.handoverDatetime && (
                          <CheckCircle color="success" fontSize="large" />
                        )}
                        <Typography
                          color={
                            order.handoverDatetime
                              ? "text.primary"
                              : "text.secondary"
                          }
                          variant="body2"
                        >
                          {order.handoverDatetime
                            ? dayjs(order.handoverDatetime).format("MMM D")
                            : "Not started"}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
};

export default CommandCenter;
