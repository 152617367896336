import { LinearProgress, Stack, Typography } from "@mui/material";
import { Button } from "@components";
import { useNavigate } from "react-router-dom";
import {
  Warning,
  CheckCircle,
  Sync,
  Cancel,
  HourglassTop,
} from "@mui/icons-material";
import { theme } from "@theme";
import { toLocaleDateTimeString } from "@utils";
import { useCallback } from "react";
import { StructureStatus, OrderStatus } from "@enums";

import { Timer } from "@pages/OrderTracker/components/PlanningAutomation/Timer";
import dayjs from "dayjs";
import { OrderWithAutomationState } from "@pages/OrderTracker/components/PlanningAutomation/utils";

import {
  AutomationStatus,
  getStatusColour,
} from "@pages/OrderTracker/components/PlanningAutomation/utils";

export const planDeliveryColor = (planDelivery: string | null) => {
  if (planDelivery === "emergency") return theme.palette.error.dark;
  if (planDelivery === "urgent") return theme.palette.warning.dark;
  return theme.palette.text.primary;
};

export const backgroundColor = (order: OrderWithAutomationState | null) => {
  if (order?.status === "manual_plan") {
    return theme.palette.success.light;
  }

  const automationStatus = order?.automationState?.currentStatus;

  switch (automationStatus) {
    case "success":
      return theme.palette.success.light;
    case "warning":
      return theme.palette.warning.light;
    case "error":
      return theme.palette.error.light;
    default:
      return theme.palette.grey[100];
  }
};

export const structStatusColor = (status: string | null) => {
  switch (status) {
    case StructureStatus.PENDING:
      return theme.palette.warning.light;
    case StructureStatus.COMPLETE:
    case StructureStatus.SKIPPED:
      return theme.palette.success.light;
    default:
      return null;
  }
};

export const structStatusIcon = (status: string | null) => {
  switch (status) {
    case StructureStatus.COMPLETE:
      return <CheckCircle color="success" fontSize="large" />;
    case StructureStatus.SKIPPED:
      return <></>;

    default:
      return <Warning color="warning" fontSize="large" />;
  }
};

export const searchTextToolTips = [
  "Check if you have any filters applied.",
  "Verify your spelling and try again.",
  "Just received a new prescription? Please wait one minute and refresh the page. ",
  "Try using the patient’s first name and last name or patient ID.",
];

export const noSearchTextToolTips = [
  "Adjusting or clearing some filters.",
  "Checking if there are any active search terms.",
];

export const StatusIcon = ({
  status,
  fontSize = "inherit",
}: {
  status: AutomationStatus;
  fontSize?: string;
}): JSX.Element => {
  if (status === "success") {
    return <CheckCircle color="success" sx={{ fontSize }} />;
  }

  if (status === "not_started") {
    return <HourglassTop color="disabled" sx={{ fontSize }} />;
  }

  if (status === "in_progress") {
    return <Sync color="secondary" sx={{ fontSize }} />;
  }

  if (status === "error") {
    return <Cancel color="error" sx={{ fontSize }} />;
  }

  return <></>;
};

export const AutomationInProgress = (props: {
  order: OrderWithAutomationState;
  navToTracker: (orderId: number, orderStatus: string) => void;
}) => {
  const automationState = props.order.automationState;

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <StatusIcon status={automationState.currentStatus} />
          <Typography
            variant="body2"
            alignItems="center"
            noWrap
            textOverflow="ellipsis"
            color={
              automationState.currentStatus === "error"
                ? "error"
                : "text.primary"
            }
          >
            {props.order.orderName}
          </Typography>

          {automationState.warnings.length > 0 && (
            <Warning color="warning" fontSize="small" />
          )}
        </Stack>
        <Button
          variant="outlined"
          onClick={() => props.navToTracker(props.order.id, props.order.status)}
        >
          <Typography noWrap variant="subtitle2">
            See More
          </Typography>
        </Button>
      </Stack>

      <LinearProgress
        sx={{ marginBottom: 1, marginTop: 1 }}
        variant="determinate"
        value={automationState.planProgressPercentage}
        color={getStatusColour(automationState.currentStatus)}
      />
      <Stack direction="row">
        <Typography variant="body2" color="text.secondary"></Typography>
        {automationState.currentStatus === "not_started" && (
          <Typography variant="body2" color="disabled">
            Queued
          </Typography>
        )}
        {automationState.currentStatus === "error" && (
          <Typography variant="body2" color="error">
            Automated Plan | Failed{" "}
            {toLocaleDateTimeString(automationState.completedTime || "")}
          </Typography>
        )}
        {automationState.currentStatus === "in_progress" && (
          <Typography variant="body2" color="text.secondary">
            Automated Plan | Processing: {automationState.currentStepIndex + 1}/
            {automationState.allSteps.length} {automationState.currentStep}
          </Typography>
        )}
        {automationState.currentStatus === "success" && (
          <>
            <Typography variant="body2" color="text.secondary">
              Automated Plan | Elapsed time:
            </Typography>{" "}
            &nbsp;
            <Timer
              startTime={dayjs(automationState.startTime) || null}
              completedTime={automationState.completedTime}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export const PlanningStatusCell = (props: {
  order: OrderWithAutomationState;
}) => {
  const navigate = useNavigate();
  const navToTracker = useCallback(
    (orderId: number, orderStatus: string) => {
      const urlEnd =
        orderStatus === OrderStatus.SENT_TO_AUTOMATION
          ? "/automated"
          : orderStatus === OrderStatus.MANUAL_PLAN
            ? "/manual"
            : "";
      return navigate(`/fulfillment/${orderId}/automation${urlEnd}`);
    },
    [navigate],
  );

  const order = props.order;
  if (order.status === "manual_plan") {
    return (
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Typography>Manual Plan</Typography>
        <Button
          variant="outlined"
          onClick={() => navToTracker(order.id, order.status)}
        >
          <Typography noWrap variant="body2">
            See More
          </Typography>
        </Button>
      </Stack>
    );
  }
  if (order.status === "sent_to_automation") {
    return <AutomationInProgress order={order} navToTracker={navToTracker} />;
  }

  if (order.status === "submitted") {
    return (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => navToTracker(order.id, order.status)}
      >
        Ready for planning
      </Button>
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={() => navToTracker(order.id, order.status)}
    >
      <Typography noWrap variant="subtitle2">
        See More
      </Typography>
    </Button>
  );
};
