import {
  Box,
  Card,
  Stack,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import {
  OrderOut,
  useAutomationIntegrationApiV1GetPlanningStepsQuery,
  useAutomationIntegrationApiV1GetPlanningProgressQuery,
  useWebApiV1GetUserFeatureSwitchesQuery,
  useWebApiV1OrderStatusChangeQuery,
  PlanningStepSequence,
  PlanningProgress,
} from "@providers/hop-ord-server/api";
import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { Timer } from "../Timer";
import {
  ErrorBox,
  StatusPageDialog,
  WarningBox,
} from "../components/StatusPageComponents";
import { StatusPageMockComponent } from "../components/StatusPageMockComponent";
import Handover from "../components/Handover";
import { Feature } from "@enums";
import {
  getAutomationStatus,
  getElapsedTimeSubtext,
  getPlanProgressPercentage,
  getStatusColour,
} from "@pages/OrderTracker/components/PlanningAutomation/utils";

interface Props {
  order: OrderOut;
}

const AutomatedPlanStatusPage = ({ order }: Props): JSX.Element => {
  const { data: orderStatus } = useWebApiV1OrderStatusChangeQuery({
    orderId: order.id,
    statusName: "sent_to_automation",
  });

  // fetch steps unless they've already been fetched
  const planningStepsRef = useRef<PlanningStepSequence | undefined>(undefined);

  const { data: planningSteps } =
    useAutomationIntegrationApiV1GetPlanningStepsQuery(
      { orderId: order.id },
      // poll until steps are available
      { pollingInterval: 5000, skip: !!planningStepsRef.current?.steps.length },
    );

  useEffect(() => {
    planningStepsRef.current = planningSteps;
  }, [planningSteps]);

  // fetch progress until progress contains the generation result
  const planningProgressRef = useRef<PlanningProgress | undefined>(undefined);

  const { data: planningProgress } =
    useAutomationIntegrationApiV1GetPlanningProgressQuery(
      { orderId: order.id },
      {
        pollingInterval: 5000,
        // skip there are no steps or there is already a planning result
        skip:
          !planningStepsRef.current?.steps.length ||
          !!planningProgressRef.current?.result,
      },
    );

  useEffect(() => {
    planningProgressRef.current = planningProgress;
  }, [planningProgress]);

  const { data: featureSwitchData } =
    useWebApiV1GetUserFeatureSwitchesQuery(undefined);

  const showMockControls = featureSwitchData?.find(
    (feature) => feature.name === Feature.MOCK_CONTROLS,
  );

  const startTime = orderStatus ? dayjs(orderStatus?.createdAt) : null;
  const completedTime = planningProgress?.result?.finishedAt
    ? dayjs(planningProgress?.result?.finishedAt)
    : null;

  const [showStatusDialog, setShowStatusDialog] = useState<boolean>(false);

  const automationStatus = getAutomationStatus(planningProgress);
  const elapsedTimeSubtext = getElapsedTimeSubtext(automationStatus);
  const planProgressPercentage = getPlanProgressPercentage(
    planningSteps?.steps || [],
    planningProgress?.currentStep || "",
  );
  const currentStepIndex =
    planningSteps?.steps.indexOf(planningProgress?.currentStep || "") ?? -1;

  return (
    <Stack gap={4}>
      <Card>
        <Box flex={1}>
          <Stack padding={2} width={1}>
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography variant="subtitle1">Planning automation</Typography>
              <Typography variant="body2">Machine {order.machine}</Typography>
            </Stack>
            <Stack direction="row" gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                {elapsedTimeSubtext}
              </Typography>
              {automationStatus !== "not_started" && (
                <Timer startTime={startTime} completedTime={completedTime} />
              )}
            </Stack>
            <LinearProgress
              sx={{ marginBottom: 2, marginTop: 3 }}
              variant="determinate"
              value={planProgressPercentage}
              color={getStatusColour(automationStatus)}
            />
            {automationStatus !== "not_started" && (
              <Typography variant="body2" color="text.secondary">
                {currentStepIndex + 1}/{planningSteps?.steps.length}{" "}
                {planningProgress?.currentStep}
              </Typography>
            )}
            {automationStatus === "error" ? (
              <ErrorBox onClick={() => setShowStatusDialog(true)} />
            ) : planningProgress?.warnings.length ? (
              <WarningBox
                onClick={() => setShowStatusDialog(true)}
                numberOfWarnings={planningProgress.warnings.length}
              />
            ) : null}
          </Stack>
        </Box>
      </Card>
      {showMockControls && (
        <StatusPageMockComponent
          orderId={order.id}
          eventDetails={planningSteps?.steps as string[]}
        />
      )}

      <Divider sx={{ width: 1 }} />

      <Handover order={order} />

      <StatusPageDialog
        warnings={planningProgress?.warnings ?? []}
        error={planningProgress?.result?.error}
        finishedAt={planningProgress?.result?.finishedAt}
        open={showStatusDialog}
        onClose={() => setShowStatusDialog(false)}
      />
    </Stack>
  );
};

export default AutomatedPlanStatusPage;
