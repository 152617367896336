import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Switch,
  Autocomplete,
  Divider,
  IconButton,
} from "@mui/material";
import {
  OrganAtRiskOut,
  OrganAtRiskConstraintOut,
} from "@providers/hop-ord-server/api";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

const LESS_THAN_OR_EQUAL = "≤";

const CONSTRAINT_OPERATOR_OPTIONS = [LESS_THAN_OR_EQUAL, "<"];

const ALT_VALUE_UNIT_OPTIONS = ["%", "cc"];

const VALUE_UNIT_OPTIONS = ["Gy", "%"];

const EMPTY_ORGAN: OrganAtRiskOut = {
  id: 0,
  sortIndex: 0,
  siteGroupId: 0,
  organ: "",
  oarConstraints: [],
  alara: false,
  maxValue: [],
  meanValue: [],
  maxValueUnit: "",
  meanValueUnit: "",
  createdAt: "",
};

const EMPTY_CONSTRAINT: OrganAtRiskConstraintOut = {
  id: -1,
  firstValue: null,
  firstValueUnit: "",
  operator: "",
  secondValue: null,
  secondValueUnit: "",
  isVolumeData: false,
};

const LABEL_WIDTH = 140;

const convertValue = (
  field: string,
  value: string,
): number | number[] | string => {
  if (field === "secondValue") {
    return value.includes("/") ? value.split("/").map(Number) : [Number(value)];
  }
  if (field === "firstValue") {
    return Number(value);
  }
  return value;
};

const constraintIsNull = (constraint: OrganAtRiskConstraintOut) => {
  return (
    constraint.firstValue === null &&
    constraint.firstValueUnit === "" &&
    constraint.operator === "" &&
    constraint.secondValue === null &&
    constraint.secondValueUnit === ""
  );
};

interface OarConstraintRowProps {
  index: number;
  isVolume: boolean;
  constraint: OrganAtRiskConstraintOut;
  disabled: boolean;
  onChange: (field: string, value: string) => void;
}

const OarConstraintRow = ({
  index,
  isVolume,
  constraint,
  disabled,
  onChange,
}: OarConstraintRowProps) => {
  const secondValue = Array.isArray(constraint?.secondValue)
    ? constraint?.secondValue?.join("/")
    : [constraint?.secondValue];
  const constraintType = isVolume ? "volume" : "dose";
  const firstValueUnitOptions = isVolume
    ? VALUE_UNIT_OPTIONS
    : ALT_VALUE_UNIT_OPTIONS;
  const secondValueUnitOptions = isVolume
    ? ALT_VALUE_UNIT_OPTIONS
    : VALUE_UNIT_OPTIONS;
  return (
    <Stack direction="row" gap={2}>
      <TextField
        value={constraint?.firstValue}
        data-testid={`first-value-${constraintType}-${index}`}
        size="small"
        disabled={disabled}
        onChange={(e) => onChange("firstValue", e.target.value)}
      />
      <Autocomplete
        value={constraint?.firstValueUnit}
        data-testid={`first-value-unit-${constraintType}-${index}`}
        options={firstValueUnitOptions}
        renderInput={(params) => <TextField {...params} />}
        size="small"
        disabled={disabled}
        onChange={(_: any, newValue: string | null) => {
          if (!newValue) return;
          onChange("firstValueUnit", newValue);
        }}
      />
      <Autocomplete
        value={constraint?.operator}
        data-testid={`operator-${constraintType}-${index}`}
        options={CONSTRAINT_OPERATOR_OPTIONS}
        renderInput={(params) => <TextField {...params} />}
        size="small"
        disabled={disabled}
        onChange={(_: any, newValue: string | null) => {
          if (!newValue) return;
          onChange("operator", newValue);
        }}
      />
      <TextField
        value={secondValue}
        data-testid={`second-value-${constraintType}-${index}`}
        disabled={disabled}
        size="small"
        onChange={(e) => onChange("secondValue", e.target.value)}
      />
      <Autocomplete
        value={constraint?.secondValueUnit}
        options={secondValueUnitOptions}
        data-testid={`second-value-unit-${constraintType}-${index}`}
        renderInput={(params) => <TextField {...params} />}
        size="small"
        disabled={disabled}
        onChange={(_: any, newValue: string | null) => {
          if (!newValue) return;
          onChange("secondValueUnit", newValue);
        }}
      />
    </Stack>
  );
};

interface OarConstraintProps {
  oar: OrganAtRiskOut;
  onChange: (oar: OrganAtRiskOut) => void;
  disabled: boolean;
}

const OarConstraint = ({ oar, onChange, disabled }: OarConstraintProps) => {
  const volumeConstraints = oar?.oarConstraints?.filter(
    (constraint: OrganAtRiskConstraintOut) => constraint?.isVolumeData,
  );
  const dConstraints = oar?.oarConstraints?.filter(
    (constraint: OrganAtRiskConstraintOut) => !constraint?.isVolumeData,
  );
  const handleChange = (index: number, field: string, value: string) => {
    // Recreate the constraints array with the updated value
    const updatedValue = convertValue(field, value);

    // Sanity check to make sure the constraint actually exists
    if (!oar.oarConstraints?.[index]) {
      return;
    }

    const updatedConstraint = {
      ...oar.oarConstraints?.[index],
      [field]: updatedValue,
    };
    const updatedOar = {
      ...oar,
      oarConstraints: oar.oarConstraints?.map(
        (constraint: OrganAtRiskConstraintOut, i: number) =>
          i === index ? updatedConstraint : constraint,
      ),
    };
    onChange(updatedOar);
  };

  const handleRemove = (index: number) => {
    // Remove the constraint
    const updatedConstraints = oar.oarConstraints?.filter(
      (_, i: number) => i !== index,
    );
    const updatedOar = {
      ...oar,
      oarConstraints: updatedConstraints,
    };
    onChange(updatedOar);
  };

  const handleAdd = (isVolumeData: boolean) => {
    const newConstraint = {
      ...EMPTY_CONSTRAINT,
      isVolumeData,
    };
    const updatedOar = {
      ...oar,
      oarConstraints: [...(oar.oarConstraints || []), newConstraint],
    };
    onChange(updatedOar);
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <Typography width={LABEL_WIDTH} paddingTop={1}>
          Volume
        </Typography>
        <Stack gap={2}>
          {volumeConstraints?.map((constraint: OrganAtRiskConstraintOut) => {
            const index = oar.oarConstraints?.indexOf(constraint) || 0;
            return (
              <Stack direction="row">
                <OarConstraintRow
                  key={index}
                  index={index}
                  isVolume={true}
                  constraint={constraint}
                  disabled={disabled}
                  onChange={(field: string, value: string) =>
                    handleChange(index, field, value)
                  }
                />
                {volumeConstraints?.length > 1 && (
                  <IconButton
                    onClick={() => handleRemove(index)}
                    disabled={disabled}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Button
        startIcon={<AddCircleOutline />}
        sx={{ maxWidth: 170, marginLeft: `${LABEL_WIDTH}px` }}
        disabled={disabled}
        onClick={() => {
          handleAdd(true);
        }}
      >
        Add constraint
      </Button>
      <Stack direction="row" gap={2}>
        <Typography width={LABEL_WIDTH} paddingTop={1}>
          D
        </Typography>
        <Stack gap={2}>
          {dConstraints?.map((constraint: OrganAtRiskConstraintOut) => {
            const index = oar.oarConstraints?.indexOf(constraint) || 0;
            return (
              <Stack direction="row">
                <OarConstraintRow
                  key={index}
                  index={index}
                  isVolume={false}
                  constraint={constraint}
                  disabled={disabled}
                  onChange={(field: string, value: string) =>
                    handleChange(index, field, value)
                  }
                />
                {dConstraints?.length > 1 && (
                  <IconButton
                    onClick={() => handleRemove(index)}
                    disabled={disabled}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Button
        startIcon={<AddCircleOutline />}
        sx={{ maxWidth: 170, marginLeft: `${LABEL_WIDTH}px` }}
        onClick={() => {
          handleAdd(false);
        }}
        disabled={disabled}
      >
        Add constraint
      </Button>
    </Stack>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (oar: OrganAtRiskOut) => void;
  organAtRisk: OrganAtRiskOut;
  organOptions: string[];
}

const OarDialog = ({
  open,
  onClose,
  onSubmit,
  organAtRisk,
  organOptions,
}: Props) => {
  const [oar, setOar] = useState<OrganAtRiskOut>(EMPTY_ORGAN);

  useEffect(() => {
    if (open) {
      // Some pre-fill of empty data so the modal can work
      const volumeConstraintsIn = organAtRisk?.oarConstraints?.filter(
        (constraint: OrganAtRiskConstraintOut) => constraint?.isVolumeData,
      );
      const dConstraintsIn = organAtRisk?.oarConstraints?.filter(
        (constraint: OrganAtRiskConstraintOut) => !constraint?.isVolumeData,
      );

      const volumeConstraints = volumeConstraintsIn?.length
        ? volumeConstraintsIn
        : [
            {
              ...EMPTY_CONSTRAINT,
              isVolumeData: true,
            },
          ];
      const dConstraints = dConstraintsIn?.length
        ? dConstraintsIn
        : [
            {
              ...EMPTY_CONSTRAINT,
              isVolumeData: false,
            },
          ];

      setOar({
        ...organAtRisk,
        oarConstraints: [...volumeConstraints, ...dConstraints],
      });
    }
  }, [organAtRisk, open]);

  useEffect(() => {
    if (!open) {
      // Clear state when dialog is closed
      setOar(EMPTY_ORGAN);
    }
  }, [open]);

  const isAlara = oar?.alara || false;
  const maxValue = oar?.maxValue?.join("/");
  const meanValue = oar?.meanValue?.join("/");
  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="oar-modal"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle display="flex" gap={1} alignItems="center">
        Organ At Risk Constraint
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} padding={2}>
          <Autocomplete
            size="small"
            data-testid="organ-select"
            value={oar?.organ}
            options={organOptions}
            renderInput={(params) => (
              <TextField data-testid="organ-select-value" {...params} />
            )}
            onChange={(_, value) => {
              if (!value) return;
              setOar({ ...oar, organ: value });
            }}
          />
          <OarConstraint
            oar={oar}
            disabled={isAlara}
            onChange={(oar) => {
              setOar(oar);
            }}
          />
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography width={LABEL_WIDTH}>Max</Typography>
            <Typography>≤</Typography>
            <TextField
              data-testid="max-value"
              value={maxValue}
              size="small"
              disabled={isAlara}
            />
            <Autocomplete
              value={oar?.maxValueUnit}
              data-testid="max-value-unit"
              size="small"
              options={VALUE_UNIT_OPTIONS}
              renderInput={(params) => <TextField {...params} />}
              disabled={isAlara}
              onChange={(_, value) => {
                if (!value) return;
                setOar({ ...oar, maxValueUnit: value });
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography width={LABEL_WIDTH}>Mean</Typography>
            <Typography>≤</Typography>
            <TextField
              data-testid="mean-value"
              value={meanValue}
              size="small"
              disabled={isAlara}
            />
            <Autocomplete
              value={oar?.meanValueUnit}
              data-testid="mean-value-unit"
              size="small"
              options={VALUE_UNIT_OPTIONS}
              renderInput={(params) => <TextField {...params} />}
              disabled={isAlara}
              onChange={(_, value) => {
                if (!value) return;
                setOar({ ...oar, meanValueUnit: value });
              }}
            />
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center">
            <Typography width={LABEL_WIDTH}>ALARA</Typography>
            <Switch
              data-testid="alara-switch"
              checked={isAlara}
              onChange={(_, value) => {
                setOar({ ...oar, alara: value });
              }}
            />
          </Stack>
          <Divider />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            // Do some filtering to remove any empty constraints
            const oarConstraints = oar.oarConstraints?.filter(
              (constraint: OrganAtRiskConstraintOut) =>
                constraintIsNull(constraint) === false,
            );
            const updatedOar = {
              ...oar,
              oarConstraints,
            };
            onSubmit(updatedOar);
          }}
          type="submit"
          data-testid="submit-btn"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OarDialog;
