import { Avatar, Stack } from "@mui/material";
import { toLocaleDateTimeString } from "@utils";

interface Props {
  name: string | null;
  patId: string | null;
  dob: string | null;
}

const PatientDetails = ({ name, patId, dob }: Props) => {
  // Convert dob from MM/DD/YYY to Month-day-year with text month
  const formatDate = (dateStr?: string) => {
    if (!dateStr) return "-";
    return toLocaleDateTimeString(dateStr, "date");
  };

  // If none of the fields are provided, set patientString to "No patient details"
  const patientString =
    name || patId || dob
      ? `${name || "-"} Pat ID: ${patId || "-"} DOB: ${dob ? formatDate(dob) : ""}`
      : "No patient details entered";

  return (
    <Stack direction="row" spacing={1} alignItems="center" typography="body2">
      <Avatar variant="rounded" sx={{ height: 24, width: 24 }} />

      <span data-testid="patient-details">{patientString}</span>
    </Stack>
  );
};

export default PatientDetails;
