import { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Divider,
  Tooltip,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import PlanningTemplate from "../components/PlanningTemplate";
import {
  useAutomationIntegrationApiV1ListTemplatesQuery,
  useAutomationIntegrationApiV1ListTemplateDetailsQuery,
  useAutomationIntegrationApiV1GetStructuresQuery,
  useAutomationIntegrationApiV1RequestStructuresMutation,
  useAutomationIntegrationApiV1RequestPlanGenerationMutation,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
  OrderStatus,
  useAutomationIntegrationApiV1ListMachinesQuery,
} from "@providers/hop-ord-server/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { OrderStatus as OrderStatusEnum, StructureStatus } from "@enums";
import { useNavigate } from "react-router-dom";

interface Props {
  orderId: number;
  defaultSelectedButton?: string;
}

const PlanningTemplateApi = ({
  orderId,
  defaultSelectedButton,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [structuresRetrieved, setStructuresRetrieved] = useState(false);
  const [structuresRequested, setStructuresRequested] = useState(false);
  const [selectedButton, setSelectedButton] = useState(
    defaultSelectedButton || "",
  );

  const [updateOrder] = useWebApiV1UpdateOrderMutation();

  /** List Data query */
  const { data: machines, isLoading: machinesLoading } =
    useAutomationIntegrationApiV1ListMachinesQuery({
      orderId,
    });

  useEffect(() => {
    // Default to the first machine if there is only one
    if (machines?.length === 1 && !selectedMachine) {
      setSelectedMachine(machines[0].name);
    }
  }, [machines]);

  // Temp options while the list data is not available
  const machineOptions = machines?.map((data) => data.name) || [];

  const { data: order, isLoading: orderLoading } = useWebApiV1OrderQuery({
    orderId,
  });
  const rtStructExists = order?.rtstructStatus === StructureStatus.COMPLETE;
  const ctExists = order?.ctStatus === StructureStatus.COMPLETE;
  const { data: templateNames, isLoading: templateNamesLoading } =
    useAutomationIntegrationApiV1ListTemplatesQuery({ orderId });

  const { data: template } =
    useAutomationIntegrationApiV1ListTemplateDetailsQuery(
      selectedTemplate
        ? { orderId, templateName: selectedTemplate }
        : skipToken,
    );

  const [sendAvailableStructuresRequest] =
    useAutomationIntegrationApiV1RequestStructuresMutation();
  const [sendPlan] =
    useAutomationIntegrationApiV1RequestPlanGenerationMutation();

  const setOrderStatus = (status: OrderStatus): Promise<any> => {
    return updateOrder({
      orderId,
      orderIn: {
        status,
      },
    });
  };

  const templateOptions = [
    "",
    ...(templateNames?.map(
      (templateName: { name: string }) => templateName.name,
    ) || []),
  ];

  const { data: availableStructures, isLoading: availableStructuresLoading } =
    useAutomationIntegrationApiV1GetStructuresQuery(
      {
        orderId,
      },
      {
        pollingInterval: 500,
        skip: !selectedTemplate || structuresRetrieved,
      },
    );

  useEffect(() => {
    if (
      !structuresRetrieved &&
      !structuresRequested &&
      !availableStructuresLoading &&
      selectedTemplate
    ) {
      sendAvailableStructuresRequest({ orderId });
      setStructuresRequested(true);
    }

    if (availableStructures?.structures?.length) {
      setStructuresRetrieved(true);
      return;
    }
  }, [
    structuresRetrieved,
    availableStructuresLoading,
    structuresRequested,
    availableStructures,
    orderId,
    selectedTemplate,
  ]);

  if (templateNamesLoading || machinesLoading) return <></>;

  return (
    <Stack width={1} height={1} marginBottom={3} gap={2}>
      <Stack direction="row" gap={1} paddingTop={1}>
        <Chip
          color={orderLoading ? "secondary" : ctExists ? "success" : "error"}
          icon={
            orderLoading ? (
              <CircularProgress size={18} />
            ) : ctExists ? (
              <CheckCircle />
            ) : (
              <Cancel />
            )
          }
          variant="outlined"
          label="CT Exists"
        />
        <Chip
          color={
            orderLoading ? "secondary" : rtStructExists ? "success" : "error"
          }
          icon={
            orderLoading ? (
              <CircularProgress size={18} />
            ) : rtStructExists ? (
              <CheckCircle />
            ) : (
              <Cancel />
            )
          }
          variant="outlined"
          label="RTStruct Exists"
        />
      </Stack>
      <Typography variant="h6">Plan delivery method</Typography>
      <Stack direction="row" justifyContent="space-between">
        <ToggleButtonGroup
          sx={{ width: "350px" }}
          value={selectedButton}
          exclusive
          onChange={(_, newAlignment) =>
            newAlignment !== null ? setSelectedButton(newAlignment) : null
          }
        >
          <Tooltip
            arrow
            title={!rtStructExists ? "Missing RTStruct file" : null}
          >
            <ToggleButton
              disabled={!rtStructExists}
              fullWidth
              value={OrderStatusEnum.SENT_TO_AUTOMATION}
            >
              Automated Plan
            </ToggleButton>
          </Tooltip>
          <ToggleButton fullWidth value={OrderStatusEnum.MANUAL_PLAN}>
            Manual Plan
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {selectedButton === OrderStatusEnum.SENT_TO_AUTOMATION ? (
        <Stack gap={2} width={1}>
          <Stack direction="row" gap={2}>
            <Autocomplete
              options={templateOptions || []}
              value={selectedTemplate}
              fullWidth
              onChange={(_: any, newValue: string | null) => {
                setSelectedTemplate(newValue || "");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Automated Template *" />
              )}
            />
            <FormControl
              sx={{ width: "50%" }}
              error={hasSubmitted && !selectedMachine}
            >
              <InputLabel id="selected-machine-label">Machine *</InputLabel>
              <Select
                id="selected-machine"
                data-testid="selected-machine"
                value={selectedMachine}
                labelId="selected-machine"
                label="Machine *"
                onChange={(e) => setSelectedMachine(e.target.value)}
              >
                {machineOptions.map((machine) => (
                  <MenuItem key={machine} value={machine}>
                    {machine}
                  </MenuItem>
                ))}
              </Select>
              {hasSubmitted && !selectedMachine && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
          </Stack>
          {(!templateNames?.length || !machineOptions?.length) && (
            <Stack direction="row" gap={1}>
              <Typography color="error">
                No templates and/or machines found.
              </Typography>
            </Stack>
          )}
          {selectedTemplate && (
            <PlanningTemplate
              orderId={orderId}
              template={template}
              availableStructures={availableStructures}
              sendPlan={sendPlan}
              selectedTemplate={selectedTemplate}
              selectedMachine={selectedMachine}
              hasSubmitted={hasSubmitted}
              setHasSubmitted={setHasSubmitted}
              setOrderStatus={() => {
                setOrderStatus("sent_to_automation");
              }}
            />
          )}
        </Stack>
      ) : selectedButton === OrderStatusEnum.MANUAL_PLAN ? (
        <Stack gap={2} width={1}>
          <Typography variant="subtitle1">
            Plan delivery method can be changed later, if needed.
          </Typography>
          <Divider />
          <Button
            size="large"
            variant="contained"
            sx={{ width: "max-content" }}
            onClick={() =>
              setOrderStatus("manual_plan").then(() => {
                navigate("manual");
              })
            }
          >
            Submit
          </Button>
        </Stack>
      ) : (
        <Divider />
      )}
    </Stack>
  );
};

export default PlanningTemplateApi;
