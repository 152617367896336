import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Stack,
  Badge,
} from "@mui/material";
import { useState, useRef } from "react";

interface FilterOption {
  label: string;
  value: string;
}

interface FilterSelectProps {
  label: string;
  startIcon: React.ReactNode;
  options: FilterOption[];
  selectedOptions: string[];
  setSelectedOptions: (selectedOptions: string[]) => void;
  defaultUnchecked?: string[];
}

const FilterSelect = ({
  label,
  startIcon,
  options,
  selectedOptions,
  setSelectedOptions,
}: FilterSelectProps) => {
  const [open, setOpen] = useState(false);
  const filters = new Set(selectedOptions);
  const badgeCount = selectedOptions.length;
  const targetRef = useRef(null);

  const handleClickMenuItem = (v: string) => {
    const newFilters = new Set(filters);
    if (filters.has(v)) {
      newFilters.delete(v);
    } else {
      newFilters.add(v);
    }
    setSelectedOptions([...newFilters.keys()]);
  };

  return (
    <>
      <Button
        ref={targetRef}
        onClick={() => setOpen(true)}
        startIcon={startIcon}
        sx={{
          paddingRight: badgeCount ? 2 : 0,
        }}
        id={`filter-select-${label}`} // test-id
      >
        <Stack direction="row" gap={2} alignItems="center">
          {label}
          <Badge badgeContent={badgeCount} color="secondary">
            <></>
          </Badge>
        </Stack>
      </Button>
      <Menu
        anchorEl={targetRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Stack overflow="auto" maxHeight={400}>
          {options.map((option: FilterOption) => (
            <MenuItem
              key={option?.value}
              onClick={() => handleClickMenuItem(option?.value)}
            >
              <Checkbox checked={filters.has(option?.value)} />
              <ListItemText primary={option?.label} />
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  );
};

export default FilterSelect;
