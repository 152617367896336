import { useCallback, useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Comments, PatientDetailsForm, SiteGroupPanel } from "@components";
import { useParams } from "react-router-dom";
import {
  useWebApiV1OrderQuery,
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1GetPatientQuery,
  useWebApiV1UpdateOrderMutation,
  OrderStatus,
} from "@providers/hop-ord-server/api";
import ProtocolPanel from "@pages/Order/components/ProtocolPanel/ProtocolPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { skipToken } from "@reduxjs/toolkit/query/react";
import PageHeader from "@components/PageHeader/PageHeader";
import AdditionalInfo from "@pages/Order/components/AdditionalInfo/AdditionalInfo";
import { orderStatusMapping } from "@enums";

const OrderPage = () => {
  const { orderId } = useParams();
  const [updateOrder] = useWebApiV1UpdateOrderMutation();
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

  // Get Order Data
  const {
    data: orderData,
    isLoading: orderIsLoading,
    error: orderError,
  } = useWebApiV1OrderQuery({
    orderId: Number(orderId),
  });

  // Get Patient Data
  const {
    data: patientData,
    isLoading: patientIsLoading,
    error: patientError,
  } = useWebApiV1GetPatientQuery(
    orderData?.patientId !== undefined && orderData?.patientId !== null
      ? { patientId: orderData.patientId }
      : skipToken,
  );

  // Get Site Groups
  const {
    data: siteGroupData,
    isLoading: siteGroupLoading,
    error: siteGroupError,
  } = useWebApiV1ListSiteGroupsQuery({ orderId: Number(orderId) });
  const isEditable = orderData?.status === "draft";

  const handleUpdateOrder = useCallback(
    (field: string, value: string) => {
      updateOrder({
        orderId: Number(orderId),
        orderIn: { ...orderData, [field]: value },
      });
    },
    [orderData, orderId, updateOrder],
  );

  useEffect(() => {
    if (siteGroupData && siteGroupData.length > 0) {
      setShowAdditionalInfo(true);
    }
    if (siteGroupData && siteGroupData.length === 0 && showAdditionalInfo) {
      setShowAdditionalInfo(false);
      handleUpdateOrder("additionalInfo", "");
    }
  }, [siteGroupData, handleUpdateOrder, showAdditionalInfo]);

  // Create the order name by joining the site group names
  const orderName =
    siteGroupData?.map((site_group) => site_group.name).join(", ") ||
    "New Order";

  // State handling
  if (orderError || patientError || siteGroupError) return <>Error</>;
  if (orderData?.patientId === undefined) {
    return <div>error</div>;
  }
  if (orderIsLoading || patientIsLoading || siteGroupLoading) {
    return <div>loading</div>;
  }

  return (
    <>
      <PageHeader
        title={orderName}
        breadcrumb={[
          { link: "/", label: "Dashboard" },
          { label: "#" + String(orderData.id).padStart(5, "0") },
        ]}
        right={
          <Select
            value={orderData.status}
            onChange={(selectedValue) => {
              updateOrder({
                orderId: Number(orderId),
                orderIn: {
                  ...orderData,
                  status: selectedValue.target.value as OrderStatus,
                },
              });
            }}
          >
            {Object.entries(orderStatusMapping)
              .filter(([key]) => key !== "draft")
              .map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
          </Select>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={8}>
          {/* Patient Details */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              Patient details
            </AccordionSummary>
            <AccordionDetails>
              <PatientDetailsForm
                patientId={orderData?.patientId ?? -1}
                orderId={Number(orderId)}
                orderData={orderData ?? {}}
                patientData={patientData}
                isReadOnly={!isEditable}
              />
            </AccordionDetails>
          </Accordion>

          {/* Protocol Panel */}
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              Planning Protocol
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={9}>
                  <ProtocolPanel
                    isReadOnly={!isEditable}
                    orderId={Number.parseInt(orderId ?? "")}
                  />
                </Grid>
                <Grid item xs={3} p={1}>
                  <Typography variant="h6">Added Protocols</Typography>
                  {siteGroupData?.map(
                    (siteGroup) =>
                      siteGroup.protocolId ?? (
                        <Typography key={siteGroup.id}>
                          {siteGroup.protocolName}
                        </Typography>
                      ),
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Site Groups */}
          {siteGroupData?.map((siteGroup, index) => (
            <SiteGroupPanel
              orderId={Number(orderId)}
              key={siteGroup.id}
              siteGroup={siteGroup}
              isReadOnly={!isEditable}
              index={index}
              doseUnit={orderData?.doseUnit || ""}
              fractionUnit={orderData?.fractionUnit || ""}
            />
          ))}

          {/* Additional info - only show if there is at least one site group added */}
          {showAdditionalInfo && (
            <AdditionalInfo
              isReadOnly={!isEditable}
              currentValue={orderData?.additionalInfo || ""}
              onBlur={handleUpdateOrder}
            />
          )}
        </Grid>
        {/* Comments */}
        <Grid item xs={4}>
          <Comments
            orderId={Number(orderId)}
            orderGroupId={Number(orderData.orderGroupId)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OrderPage;
