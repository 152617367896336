import { Button } from "@components";
import { Box, Stack, Typography } from "@mui/material";
import {
  OrderOut,
  useWebApiV1UpdateOrderMutation,
} from "@providers/hop-ord-server/api";
import { toLocaleDateTimeString } from "@utils";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { FormContainer, useForm } from "react-hook-form-mui";
// @ts-expect-error types for the package are not up to date
import { DateTimePickerElement } from "react-hook-form-mui/date-pickers";

interface Props {
  order: OrderOut;
}

const Handover = ({ order }: Props) => {
  const [updateOrder] = useWebApiV1UpdateOrderMutation();
  const [handoverSubmitted, setHandoverSubmitted] = useState<boolean>(false);
  const now = dayjs();
  const form = useForm<{ handoverDatetime: Dayjs }>({
    defaultValues: {
      handoverDatetime: dayjs(),
    },
    mode: "all",
  });

  const submitHandover = () => {
    const formValues = form.getValues();
    if (!formValues.handoverDatetime) return;

    updateOrder({
      orderId: Number(order.id),
      orderIn: { handoverDatetime: formValues.handoverDatetime.toISOString() },
    });
    setHandoverSubmitted(true);
  };

  return (
    <Box flex={1}>
      <Stack gap={2}>
        <Typography variant="h6">Handover</Typography>

        {order.handoverDatetime ? (
          <>
            <Typography>
              Handover completed successfully on{" "}
              {toLocaleDateTimeString(order.handoverDatetime, "date")} at{" "}
              {toLocaleDateTimeString(order.handoverDatetime, "time")}
            </Typography>

            <Button sx={{ width: "320px" }} variant="outlined" href="/">
              &lt; Back to Command Center
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body2">
              By marking this as complete, you confirm that the physician has
              approved the plan and the handover process with the local planning
              team is finished
            </Typography>
            <FormContainer formContext={form}>
              <DateTimePickerElement
                required
                maxDate={now}
                name="handoverDatetime"
                label="Date time completed"
                desktopModeMediaQuery="(min-width:600px)"
                inputProps={{
                  onBlur: () => form.trigger("handoverDatetime"),
                  name: "handoverDatetime",
                }}
                disabled={handoverSubmitted}
                data-testid="form-handover-date-time"
              />
            </FormContainer>
            <Button
              sx={{ width: "max-content" }}
              variant="contained"
              onClick={submitHandover}
              disabled={handoverSubmitted}
            >
              Mark as complete
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Handover;
