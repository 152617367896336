import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";
import { theme } from "@theme";
import { ThemeProvider } from "@mui/material";
import ErrorPage from "./pages/Shared/ErrorPage/ErrorPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LogoutOnInactivityPage from "@pages/LogoutOnInactivity/LogoutOnInactivityPage";
import * as Sentry from "@sentry/react";
import MobilePage from "@pages/Mobile/MobilePage";
import { isMobileOnly } from "react-device-detect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

import "dayjs/locale/en-au";
import "dayjs/locale/en";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const adapterLocale = (region: string) => {
  switch (region.slice(0, 2)) {
    case "us":
      return "en";
    case "ap":
    default:
      return "en-au";
  }
};

const release = process.env.VITE_GIT_COMMIT_SHA;

const browserTracing = Sentry.reactRouterV6BrowserTracingIntegration({
  useEffect: React.useEffect,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes: matchRoutes,
});

const integrations = [browserTracing];
const replayEnvs = ["preprod", "prod"];
// Only enable replay in preprod. In future, we need to enable in prod.
if (
  process.env.VITE_ENVIRONMENT_TYPE &&
  replayEnvs.includes(process.env.VITE_ENVIRONMENT_TYPE)
) {
  const replay = Sentry.replayIntegration({
    maskAllText: true,
    blockAllMedia: true,
  });
  integrations.push(replay);
}

Sentry.init({
  normalizeDepth: 10,
  dsn: process.env.VITE_SENTRY_DSN,
  tunnel: process.env.VITE_ORDER_SERVER_URL + "ord/sentry-tunnel/",
  integrations: integrations,
  release: release,
  environment: process.env.VITE_ENVIRONMENT_TYPE,
  replaysSessionSampleRate: parseFloat(
    process.env.VITE_SENTRY_SESSION_RATE || "0",
  ),
  replaysOnErrorSampleRate: parseFloat(
    process.env.VITE_SENTRY_ERROR_RATE || "0",
  ),
});
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

if (isMobileOnly) {
  if (window.location.pathname !== "/mobile") window.location.href = "/mobile";
}

const router = sentryCreateBrowserRouter(
  createRoutesFromChildren(
    <>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/mobile" element={<MobilePage />} />
      <Route path="/inactivity" element={<LogoutOnInactivityPage />} />
      <Route
        path="/*"
        element={
          <Auth0ProviderWithNavigate>
            <Sentry.ErrorBoundary
              fallback={<div>An error has occurred. Please refresh.</div>}
            >
              {!isMobileOnly && <App />}
            </Sentry.ErrorBoundary>
          </Auth0ProviderWithNavigate>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </>,
  ),
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={adapterLocale(process.env.VITE_AWS_REGION || "")}
      >
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
);
