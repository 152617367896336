import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import PatientDetails from "@components/PatientDetails/PatientDetails";
import { OrderOut } from "@providers/hop-ord-server/api";
import { UploadedFile } from "@components/OrderTrackingFileList/Interface";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  scorecardFiles: UploadedFile[];
  walkthroughFiles: UploadedFile[];
  orderData: OrderOut;
}
const SendForReviewDialog = ({
  open,
  setOpen,
  onConfirm,
  scorecardFiles,
  walkthroughFiles,
  orderData,
}: Props) => {
  return (
    <Dialog open={open} maxWidth="xs" data-testid="send-for-review-dialog">
      <DialogTitle>Send for review?</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <div>
            Are you sure you want to send the following documents for RO
            approval?
          </div>
          <Typography variant="button">Scorecards</Typography>
          <ol style={{ margin: 0, paddingLeft: "1.5rem" }}>
            {scorecardFiles.map((file, idx) => (
              <li key={`file-${idx}`} data-testid={`file-${idx}`}>
                {file.baseFile.name}
              </li>
            ))}
          </ol>
          <Typography variant="button">Plan walkthroughs</Typography>
          <ol style={{ margin: 0, paddingLeft: "1.5rem" }}>
            {walkthroughFiles.map((file, idx) => (
              <li key={`file-${idx}`} data-testid={`file-${idx}`}>
                {file.baseFile.name}
              </li>
            ))}
          </ol>
          <Divider />
          <PatientDetails
            name={orderData?.patientName || ""}
            dob={orderData?.patientDob || ""}
            patId={orderData?.patientMrn || ""}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-testid="dialog-cancel-btn" onClick={() => setOpen(false)}>
          cancel
        </Button>
        <Button data-testid="dialog-send-btn" onClick={onConfirm}>
          send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendForReviewDialog;
