import { OrderTable, PageHeader } from "@components";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  OrderStatus as OrderStatusType,
  useWebApiV1CreateOrderMutation,
} from "@providers/hop-ord-server/api";

import { OrderStatus } from "@enums";

const DosimetristDashboard = () => {
  const [addOrder] = useWebApiV1CreateOrderMutation();
  const navigate = useNavigate();
  const statusDefaultFilters = [
    OrderStatus.DRAFT,
    OrderStatus.SUBMITTED,
    OrderStatus.IN_PROGRESS,
    OrderStatus.READY_FOR_RO_APPROVAL,
    OrderStatus.APPROVED,
    OrderStatus.REJECTED,
    OrderStatus.COMPLETED,
  ];

  const createOrder = async () => {
    const order = await addOrder().unwrap();
    navigate(`/order/${order}`);
  };

  const handleOrderSelect = useCallback(
    (orderId: number, status: OrderStatusType) => {
      if (status !== OrderStatus.DRAFT) {
        navigate(`/fulfillment/${orderId}/tracker`);
      } else {
        navigate(`/order/${orderId}`);
      }
    },
    [navigate],
  );
  return (
    <Stack gap={1.5} pt={1.5}>
      <PageHeader title="Planning Dashboard" />
      <Box padding={1.5} marginBottom={2}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => createOrder()}
          startIcon={<AddIcon />}
        >
          New Order
        </Button>
      </Box>
      <OrderTable
        handleOrderSelect={handleOrderSelect}
        statusDefaultFilters={statusDefaultFilters}
        recent={false}
      />
    </Stack>
  );
};

export default DosimetristDashboard;
