import { SiteTable } from "@components";
import { IconButton, Stack } from "@mui/material";
import {
  useEffect,
  useState,
  useMemo,
  useImperativeHandle,
  Ref,
  useRef,
  useCallback,
} from "react";
import {
  DosePrescription,
  SiteGroupName,
} from "@components/SiteTable/SiteTable.types";
import { GridValidRowModel } from "@mui/x-data-grid";
import {
  useWebApiV1CreateSiteMutation,
  useWebApiV1UpdateSiteMutation,
  useWebApiV1ListSitesQuery,
  useWebApiV1DeleteSiteMutation,
  useWebApiV1CreateOarMutation,
  useWebApiV1UpdateOarMutation,
  useWebApiV1ListOarsQuery,
  useWebApiV1DeleteOarMutation,
  SiteOut,
  OrganAtRiskIn,
  useProtocolApiV1ListDataQuery,
  useProtocolApiV1TreatmentSiteDataQuery,
  useProtocolApiV1OrganDataQuery,
  OrganAtRiskOut,
} from "@providers/hop-ord-server/api";
import OarTable, { OarInfo } from "@components/OarTable/OarTable";
import {
  areDoseValuesValid,
  isNumeric,
  isSpecifyMandatory,
  parseListData,
} from "@utils";
import { ListDataCategory } from "@enums";
import BolusFields from "@components/BolusFields/BolusFields";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { TextOverflowTooltip } from "@components";
import React from "react";
import { SiteTableRefObject } from "@components/SiteTable/SiteTable";
import SiteGroupPanelSkeleton from "./SiteGroupPanelSkeleton";
import { SiteGroupOut } from "@providers/hop-ord-server/api";
import { getDoses } from "@utils/doseAndFractions";

export interface SiteGroupPanelProps {
  index: number;
  orderId: number;
  isReadOnly: boolean;
  siteGroup: SiteGroupOut;
  children?: React.ReactNode;
  submitCount?: number;
  onDeleteCallback?: (siteGroup: SiteGroupOut) => void;
  reportValidity?: (id: number | string, isValid: boolean) => void;
  onLoadingChange?: (isLoading: boolean) => void;
  showOrderName?: boolean;
  doseUnit?: string;
  fractionUnit?: string;
}

const removeInvalidSiteData = (
  site: DosePrescription,
  listOptions: { [k: string]: string[] },
  isReadOnly: boolean,
): DosePrescription => {
  const modifiedSite = { ...site };
  const errors: string[] = [];
  const touched: string[] = [];

  const checkOpts = (optName: string, value: string, altName?: string) => {
    if (
      !isReadOnly &&
      (value === "" ||
        !listOptions[altName ?? optName] ||
        !listOptions[altName ?? optName].includes(value))
    ) {
      errors.push(optName);
      return "";
    }
    return value;
  };

  modifiedSite.name = checkOpts("name", site.name, "treatmentSite");

  if (site.name && isSpecifyMandatory(site.name) && !site.nameSpecify) {
    errors.push("name");
  }

  modifiedSite.laterality = checkOpts("laterality", site.laterality);
  modifiedSite.technique = checkOpts("technique", site.technique);
  modifiedSite.sequence = checkOpts("sequence", site.sequence);
  modifiedSite.isodose = checkOpts("isodose", site.isodose);
  modifiedSite.frequency = checkOpts("frequency", site.frequency);
  modifiedSite.energy = checkOpts("energy", site.energy);

  // other stuff
  if (
    (site.fractions !== null && !isNumeric(site.fractions)) ||
    site.fractions === null
  ) {
    modifiedSite.fractions = null;
    errors.push("fractions");
  }
  if (site.highDose === null || !isNumeric(site.highDose)) {
    modifiedSite.highDose = null;
    errors.push("dose");
  }
  if (site.lowDose !== null && !isNumeric(site.lowDose)) {
    modifiedSite.lowDose = null;
    errors.push("dose");
  }
  if (site.mid1Dose !== null && !isNumeric(site.mid1Dose)) {
    modifiedSite.mid1Dose = null;
    errors.push("dose");
  }
  if (site.mid2Dose !== null && !isNumeric(site.mid2Dose)) {
    modifiedSite.mid2Dose = null;
    errors.push("dose");
  }
  const isDoseValid = areDoseValuesValid(
    site.highDose || null,
    site.mid1Dose || null,
    site.mid2Dose || null,
    site.lowDose || null,
  );
  if (!isDoseValid) {
    errors.push("dose");
  }
  modifiedSite.errors = errors;
  modifiedSite.touched = modifiedSite.touched || touched;
  return modifiedSite;
};

const removeInvalidOarData = (oarData: OarInfo) => {
  const modifiedOarData = { ...oarData };
  const errors: string[] = [];
  const touched: string[] = [];
  modifiedOarData.errors = errors;
  modifiedOarData.touched = modifiedOarData.touched || touched;
  return modifiedOarData;
};

export const siteGroupName = (
  dosePrescriptions: SiteGroupName[],
  doseUnit: string,
  fractionUnit: string,
): string => {
  const siteNames = dosePrescriptions.map((site) => {
    const doses = getDoses(site).join("-");
    if (!site.name) return "";
    const specifyLabel = site.nameSpecify ? ` ${site.nameSpecify}` : "";
    return `${site.name}${specifyLabel} ${site.technique} ${doses}${doseUnit}/${
      site.fractions || ""
    }${fractionUnit}`;
  });
  const siteName = siteNames.join(" + ");
  return siteName;
};

export interface SiteGroupPanelRefObject {
  trigger: () => boolean;
}
const categories = [
  ListDataCategory.TREATMENT_SITE,
  ListDataCategory.ISODOSE,
  ListDataCategory.LATERALITY,
  ListDataCategory.TECHNIQUE,
  ListDataCategory.SEQUENCE,
  ListDataCategory.FREQUENCY,
  ListDataCategory.ENERGY,
  ListDataCategory.TREATING_DEPARTMENT,
];
const SiteGroupPanel = React.forwardRef(
  (props: SiteGroupPanelProps, ref: Ref<SiteGroupPanelRefObject>) => {
    const {
      reportValidity,
      submitCount,
      index,
      isReadOnly,
      orderId,
      siteGroup,
      onDeleteCallback,
      onLoadingChange,
      showOrderName = true,
      doseUnit,
      fractionUnit,
    } = props;
    const siteGroupId = (siteGroup.id as number) || 0;
    const [dosePrescriptions, setDosePrescriptions] = useState<
      DosePrescription[]
    >([]);
    const siteTableRef = useRef<SiteTableRefObject>(null);
    const [isSiteGroupValid, setIsSiteGroupValid] = useState<boolean>(false);
    const [oars, setOars] = useState<OarInfo[]>([]);
    const [addOar] = useWebApiV1CreateOarMutation();
    const [updateOar] = useWebApiV1UpdateOarMutation();
    const [deleteOar] = useWebApiV1DeleteOarMutation();
    const [addSite] = useWebApiV1CreateSiteMutation();
    const [updateSite] = useWebApiV1UpdateSiteMutation();
    const [deleteSite] = useWebApiV1DeleteSiteMutation();
    const [bolusValidationTrigger, setBolusValidationTrigger] = useState<
      (() => Promise<boolean>) | null
    >(null);
    const { data, isLoading } = useWebApiV1ListSitesQuery({
      orderId: orderId.toString(),
      siteGroupId: siteGroupId.toString(),
    });
    const { data: oarData, isLoading: oarIsLoading } = useWebApiV1ListOarsQuery(
      {
        orderId: orderId.toString(),
        siteGroupId: siteGroupId.toString(),
      },
    );

    /** Treatment site query */
    const { data: treatmentSiteData } =
      useProtocolApiV1TreatmentSiteDataQuery();
    /** Treatment site query */
    const { data: organData } = useProtocolApiV1OrganDataQuery();

    /** List Data query */
    const { data: listData, isLoading: listDataLoading } =
      useProtocolApiV1ListDataQuery({
        categories,
      });

    const listOptions = useMemo(() => {
      const options = parseListData(listData || [], categories);
      options[ListDataCategory.TREATMENT_SITE] =
        treatmentSiteData?.map((site) => site.name) || [];
      options[ListDataCategory.ORGAN] =
        organData?.map((organ) => organ.name) || [];
      return options;
    }, [listData, treatmentSiteData, organData]);

    const treatmentSiteOptions = listOptions[ListDataCategory.TREATMENT_SITE];
    const techniqueOptions = listOptions[ListDataCategory.TECHNIQUE];
    const isodoseOptions = listOptions[ListDataCategory.ISODOSE];
    const lateralityOptions = listOptions[ListDataCategory.LATERALITY];
    const sequenceOptions = listOptions[ListDataCategory.SEQUENCE];
    const frequencyOptions = listOptions[ListDataCategory.FREQUENCY];
    const energyOptions = listOptions[ListDataCategory.ENERGY];
    const organOptions = listOptions[ListDataCategory.ORGAN];

    useImperativeHandle(ref, () => ({
      trigger: () => {
        // is data same as dosePrescriptions and also oars
        const doseDataInSync = dosePrescriptions.every((site) =>
          data?.some(
            (s) =>
              s.id === site.id &&
              s.name === site.name &&
              s.highDose === site.highDose &&
              s.mid1Dose === site.mid1Dose &&
              s.mid2Dose === site.mid2Dose &&
              s.lowDose === site.lowDose &&
              s.fractions === site.fractions &&
              s.technique === site.technique &&
              s.isodose === site.isodose &&
              s.laterality === site.laterality &&
              s.sequence === site.sequence &&
              s.frequency === site.frequency &&
              s.energy === site.energy,
          ),
        );
        const siteTableOk = siteTableRef.current?.trigger() && doseDataInSync;
        return siteTableOk || false;
      },
    }));

    useEffect(() => {
      if (oarData) {
        setOars(oarData.map((oar) => removeInvalidOarData(oar)));
      }
    }, [oarData]);

    // TEMP validation passed back to the OrderPage
    const [oarsAreValid, setOarsAreValid] = useState<boolean>(false);

    useEffect(() => {
      if (!reportValidity) return;
      reportValidity(siteGroupId, isSiteGroupValid);
    }, [isSiteGroupValid, reportValidity, siteGroupId]);

    const validateSiteGroup = useCallback(async () => {
      const isDosePrescriptionsValid = dosePrescriptions.every(
        (site) => site.errors && site.errors.length === 0,
      );
      const bolusIsValid =
        bolusValidationTrigger !== null && (await bolusValidationTrigger());
      const siteGroupIsValid =
        dosePrescriptions.length > 0 &&
        isDosePrescriptionsValid &&
        oarsAreValid &&
        bolusIsValid;
      setIsSiteGroupValid(siteGroupIsValid);
    }, [dosePrescriptions, oarsAreValid, bolusValidationTrigger]);

    useEffect(() => {
      validateSiteGroup();
    }, [dosePrescriptions, oarsAreValid, submitCount, validateSiteGroup]);

    useEffect(() => {
      if (submitCount && submitCount > 0) {
        // update the table to touch all the fields
        setDosePrescriptions((prescription) => {
          return prescription.map((site) => {
            return {
              ...site,
              touched: [
                "name",
                "laterality",
                "technique",
                "sequence",
                "isodose",
                "frequency",
                "energy",
                "fractions",
                "dose",
              ],
            };
          });
        });
        setOars((oars) => {
          return oars.map((oar) => ({ ...oar, touched: ["organ"] }));
        });
      }
    }, [submitCount]);

    useEffect(() => {
      // if one of the list data category is empty then it won't show any data in table :(
      if (data) {
        //&& Object.values(listOptions).every((v) => v.length > 0)) {
        // keep the doseprescription fields that are touched.
        setDosePrescriptions((prevRows) => {
          return data.map((site) => {
            const newSite = removeInvalidSiteData(
              site,
              listOptions,
              isReadOnly,
            );
            const prevRow = prevRows.find((row) => row.id === site.id);
            if (prevRow) {
              newSite.touched = prevRow.touched;
            }
            return newSite;
          });
        });
      }
    }, [data, listOptions]);

    useEffect(() => {
      onLoadingChange &&
        onLoadingChange(isLoading || oarIsLoading || listDataLoading);
    }, [isLoading, oarIsLoading, listDataLoading, onLoadingChange]);

    const setBolusValidationTriggerCallback = useCallback(
      (fn: () => Promise<boolean>) => setBolusValidationTrigger(() => fn),
      [],
    );

    if (isLoading || oarIsLoading || listDataLoading) {
      return <SiteGroupPanelSkeleton />;
    }
    // Throw error if the doseUnit or fractionUnit is not provided
    if (
      doseUnit === "" ||
      doseUnit === undefined ||
      fractionUnit === "" ||
      fractionUnit === undefined
    ) {
      throw new Error("Dose and fraction units are required");
    }
    return (
      <Stack
        key={siteGroupId}
        id={`site-group-panel-${siteGroupId}`}
        sx={{ scrollMarginTop: "17rem" }}
      >
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          overflow="hidden"
        >
          {showOrderName && (
            <TextOverflowTooltip paddingY={2} variant="h6">
              {siteGroupName(
                dosePrescriptions,
                doseUnit || "",
                fractionUnit || "",
              ) || `Site Group ${index + 1}`}
            </TextOverflowTooltip>
          )}

          {!isReadOnly && (
            <IconButton
              onClick={() => {
                onDeleteCallback && onDeleteCallback(siteGroup);
              }}
              data-testid={`delete-site-group-${siteGroupId}`}
              style={{ marginLeft: 4 }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
        <Stack spacing={2}>
          <SiteTable
            dataTestid={`site-table-${siteGroupId}`}
            ref={siteTableRef}
            isReadOnly={props.isReadOnly}
            dosePrescriptions={dosePrescriptions}
            treatmentSiteOptions={treatmentSiteOptions}
            lateralityOptions={lateralityOptions}
            techniqueOptions={techniqueOptions}
            sequenceOptions={sequenceOptions}
            isodoseOptions={isodoseOptions}
            frequencyOptions={frequencyOptions}
            energyOptions={energyOptions}
            doseUnit={props.doseUnit || ""}
            fractionUnit={props.fractionUnit || ""}
            addSite={(name, nameSpecify) => {
              addSite({
                siteGroupId: siteGroupId,
                manualSiteName: name,
                manualSiteSpecify: nameSpecify,
              }).then((res) => {
                const siteData = (res as unknown as { data: SiteOut }).data;
                const newRow: DosePrescription = {
                  ...siteData,
                  errors: [],
                  touched: [],
                };
                setDosePrescriptions((prevRows) => [...prevRows, newRow]);
              });
            }}
            removeSite={(id: string | number): void => {
              deleteSite({ siteId: id as number });
              setDosePrescriptions((prevRows) =>
                prevRows.filter((row) => row.id !== id),
              );
            }}
            updateSite={(site: GridValidRowModel): boolean => {
              const modifiedSite = removeInvalidSiteData(
                site as DosePrescription,
                listOptions,
                isReadOnly,
              );
              updateSite({
                siteId: site.id as number,
                siteOut: modifiedSite as SiteOut,
              });
              setDosePrescriptions((prevRows) =>
                prevRows.map((row) =>
                  row.id === site.id ? modifiedSite : row,
                ),
              );
              return true;
            }}
            submitCount={props.submitCount}
          />
          <BolusFields
            siteGroup={props.siteGroup}
            isReadOnly={props.isReadOnly}
            setValidationTrigger={setBolusValidationTriggerCallback}
          />
          <OarTable
            dataTestId={`oar-table-${siteGroupId}`}
            isReadOnly={props.isReadOnly}
            oarData={oars}
            updateOar={(oar: OrganAtRiskOut): boolean => {
              updateOar({
                oarId: oar.id as number,
                organAtRiskIn: oar as OrganAtRiskIn,
              });
              setOars((prevRows) =>
                prevRows.map((row) =>
                  row.id === oar.id ? (oar as OrganAtRiskOut) : row,
                ),
              );
              return true;
            }}
            removeOar={(id: string | number): void => {
              deleteOar({
                oarId: id as number,
              });
              setOars((prevRows) => prevRows.filter((row) => row.id !== id));
            }}
            addOar={() => {
              addOar({ siteGroupId }).then((res) => {
                const oarData = (res as unknown as { data: OrganAtRiskOut })
                  .data;
                const newRow: OrganAtRiskOut = {
                  ...oarData,
                };
                setOars((prevRows) => [...prevRows, newRow]);
              });
            }}
            organOptions={organOptions}
            submitCount={props.submitCount}
            reportValidity={setOarsAreValid}
          />
        </Stack>
      </Stack>
    );
  },
);

export default SiteGroupPanel;
