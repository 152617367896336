import { Stack, TextField, InputAdornment, Button } from "@mui/material";
import {
  Search,
  KeyboardDoubleArrowRight,
  LocationCity,
  Person,
  FilterList,
  Clear,
} from "@mui/icons-material";
import { FilterSelect } from "@components";
import {
  useProtocolApiV1ListDataQuery,
  useWebApiV1GetTenantPhysiciansQuery,
  ListDataOptionSerialised,
  UserOptionOut,
} from "@providers/hop-ord-server/api";
import { ListDataCategory } from "@enums";

interface Props {
  searchText: string;
  setSearchText: (searchText: string) => void;
  selectedStatus: string[];
  setSelectedStatus: (selectedStatus: string[]) => void;
  selectedSite: string[];
  setSelectedSite: (selectedSite: string[]) => void;
  selectedPhysician: string[];
  setSelectedPhysician: (selectedPhysician: string[]) => void;
  selectedQuickFilters: string[];
  setSelectedQuickFilters: (selectedQuickFilters: string[]) => void;
}

const sortByName = (a: string, b: string) => a.localeCompare(b);

const STATUS_OPTIONS = [
  { label: "New prescriptions", value: "New prescriptions" },
  { label: "Ready for planning", value: "Ready for planning" },
  { label: "Planning (Automation)", value: "Planning (Automation)" },
  { label: "Planning (Manual)", value: "Planning (Manual)" },
  { label: "Plan review", value: "Plan review" },
  { label: "Handover complete", value: "Handover complete" },
];

const QUICK_FITLERS = [
  { label: "Unread comments", value: "Unread comments" },
  { label: "Prescription changes", value: "Prescription changes" },
  // { label: "Test view", value: "Test view" },
];

const SearchAndFilterBar = ({
  searchText,
  setSearchText,
  selectedStatus,
  setSelectedStatus,
  selectedSite,
  setSelectedSite,
  selectedPhysician,
  setSelectedPhysician,
  selectedQuickFilters,
  setSelectedQuickFilters,
}: Props) => {
  const { data: treatingDepartments } = useProtocolApiV1ListDataQuery({
    categories: [ListDataCategory.TREATING_DEPARTMENT],
  });
  const { data: physicians } = useWebApiV1GetTenantPhysiciansQuery();

  const siteOptions =
    treatingDepartments
      ?.map((listData: ListDataOptionSerialised) => ({
        value: listData?.value,
        label: listData?.value,
      }))
      .sort((a, b) => sortByName(a.label, b.label)) || [];
  const physicianOptions =
    physicians
      ?.map((physician: UserOptionOut) => ({
        label: physician?.name,
        value: String(physician?.id),
      }))
      .sort((a, b) => sortByName(a.label, b.label)) || [];

  const clearAllFilters = () => {
    setSelectedStatus([]);
    setSelectedSite([]);
    setSelectedPhysician([]);
    setSelectedQuickFilters([]);
  };

  const anyFilterSelected =
    selectedStatus.length > 0 ||
    selectedSite.length > 0 ||
    selectedPhysician.length > 0 ||
    selectedQuickFilters.length > 0;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TextField
        id="search-text"
        placeholder="Search patient name or ID"
        variant="standard"
        value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
        sx={{
          width: "250px",
          marginRight: 2,
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          },
        }}
      />
      <FilterSelect
        label="Status"
        options={STATUS_OPTIONS}
        selectedOptions={selectedStatus}
        setSelectedOptions={setSelectedStatus}
        startIcon={<KeyboardDoubleArrowRight />}
      />
      <FilterSelect
        label="Site"
        options={siteOptions}
        selectedOptions={selectedSite}
        setSelectedOptions={setSelectedSite}
        startIcon={<LocationCity />}
      />
      <FilterSelect
        label="Physician"
        options={physicianOptions}
        selectedOptions={selectedPhysician}
        setSelectedOptions={setSelectedPhysician}
        startIcon={<Person />}
      />
      <FilterSelect
        label="Quick Filters"
        options={QUICK_FITLERS}
        selectedOptions={selectedQuickFilters}
        setSelectedOptions={setSelectedQuickFilters}
        startIcon={<FilterList />}
      />
      {anyFilterSelected && (
        <Button
          disabled={!anyFilterSelected}
          startIcon={<Clear />}
          onClick={clearAllFilters}
        >
          Clear Filters
        </Button>
      )}
    </Stack>
  );
};

export default SearchAndFilterBar;
